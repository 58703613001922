import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import CircledCrossIcon from '@/assets/icons/circled-cross.svg?react';
import DowntrendIcon from '@/assets/icons/downtrend.svg?react';
import GearIcon from '@/assets/icons/gear.svg?react';
import LightningIcon from '@/assets/icons/lightning.svg?react';
import UptrendIcon from '@/assets/icons/uptrend.svg?react';
import { Icon } from '@/common/components/icon';
import { LocationMarker } from '@/common/components/leaflet/location-marker';
import { MapComponent } from '@/common/components/leaflet/map';
import { Widget } from '@/common/components/widget';
import { FAST_CHARGE_TRESHOLD, MAP_START_POSITION } from '@/common/constants/const';
import { PageLayout } from '@/common/layouts/page-layout';
import { getChargeSiteLatLng } from '@/common/utils/utils';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { chargeSiteConnectorsStatsPrefetch } from '@/tanstack/prefetch/charge-site-connector-stats.prefetch';
import { useChargeSitesWithinArea } from '@/tanstack/queries/charge-site/use-charge-sites-area.query';

import { DashboardMapTooltip } from './dashboard-map-tooltip';

const getMapStartPosition = (): [number, number] => {
  const savedMapPositionRaw = localStorage.getItem('mapStartPosition');

  return savedMapPositionRaw
    ? JSON.parse(savedMapPositionRaw)
    : [MAP_START_POSITION.lat, MAP_START_POSITION.lng];
};

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { data } = useChargeSitesWithinArea(...getMapStartPosition());
  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);

  return (
    <PageLayout title={t('DASHBOARD_PAGE.PAGE_NAME')}>
      <div className="min-h-96 mb-4">
        {data ? (
          <MapComponent onMapClick={() => setSelectedSiteId(null)}>
            {data.map((chargeSite) => {
              const isLocationActive = chargeSite.chargePoints.some((chargePoint) =>
                chargePoint.connectors.some((connector) => connector.state.isActive),
              );
              const isFastCharge = chargeSite.chargePoints.some((chargePoint) =>
                chargePoint.connectors.some(
                  (connector) => connector.powerWt >= FAST_CHARGE_TRESHOLD,
                ),
              );

              return (
                <LocationMarker
                  position={getChargeSiteLatLng(chargeSite)}
                  key={chargeSite.id}
                  isSelected={selectedSiteId === chargeSite.id}
                  isActive={isLocationActive}
                  isFastCharge={isFastCharge}
                  eventHandlers={{
                    click: () => setSelectedSiteId(chargeSite.id),
                    mouseover: () => {
                      chargeSiteConnectorsStatsPrefetch(chargeSite.id);
                    },
                  }}
                  tooltipContent={selectedSiteId && <DashboardMapTooltip chargeSite={chargeSite} />}
                />
              );
            })}
          </MapComponent>
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col gap-4 mb-4 w-full">
          <div className="flex gap-4">
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.SUCCESSFUL_SESSIONS')}
              content={'0'}
              linkTo="#"
              contentProps={{ size: '5xl' }}
              reactIcon={<Icon reactIcon={<LightningIcon />} />}
            />
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.AVERAGE_SUCCESS_RATE')}
              content={'0%'}
              contentProps={{ size: '5xl' }}
              reactIcon={<Icon reactIcon={<UptrendIcon />} />}
            />
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.AVERAGE_UTILIZATION_RATE')}
              content={'0%'}
              contentProps={{ size: '5xl' }}
              reactIcon={<Icon reactIcon={<DowntrendIcon />} />}
            />
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.NETWORK_UPTIME')}
              content={'0%'}
              contentProps={{ size: '5xl' }}
              reactIcon={<Icon reactIcon={<DowntrendIcon />} />}
            />
            <Widget
              title={t('DASHBOARD_PAGE.MAINTENANCE_TASKS')}
              fullWidth
              content={'0'}
              contentProps={{ size: '5xl' }}
              reactIcon={<Icon reactIcon={<GearIcon />} />}
            />
            <Widget
              title={t('DASHBOARD_PAGE.FAILED_CHARGING_SESSIONS')}
              fullWidth
              content={'0'}
              contentProps={{ size: '5xl' }}
              reactIcon={<Icon reactIcon={<CircledCrossIcon className="text-destructive" />} />}
            />
          </div>
          <div className="flex gap-4">
            <Widget
              title={t('DASHBOARD_PAGE.TOTAL_REVENUE_GENERATED')}
              fullWidth
              doubleHeightContent
              contentProps={{ size: '5xl' }}
            />
            <Widget
              title={t('DASHBOARD_PAGE.AVERAGE_SESSION_DURATION')}
              fullWidth
              doubleHeightContent
              contentProps={{ size: '5xl' }}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
