import { useEffect } from 'react';

import { LatLngBounds } from 'leaflet';
import { useMap } from 'react-leaflet';

type Props = {
  bounds: LatLngBounds;
};

export const FitBounds = ({ bounds }: Props) => {
  const map = useMap();

  useEffect(() => {
    if (bounds.isValid()) {
      map.fitBounds(bounds, { padding: [20, 20] });
    }
  }, [map, bounds]);

  return null;
};
