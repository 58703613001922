import { HTMLAttributes } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/common/utils/utils';

const skeletonVariants = cva('rounded-md p-4 bg-background', {
  variants: {
    width: {
      auto: 'w-full',
      widget: 'w-52',
    },
    height: {
      auto: 'h-full',
      widget: 'h-32',
      tableRow: 'h-14',
      table: 'h-76',
    },
  },
  defaultVariants: {
    width: 'auto',
    height: 'auto',
  },
});

export interface SkeletonProps
  extends HTMLAttributes<HTMLElement>,
    VariantProps<typeof skeletonVariants> {}

function Skeleton({ className, width, height, ...props }: SkeletonProps) {
  let content = <div className="w-full h-full bg-background-muted rounded-md animate-pulse" />;

  if (height === 'widget') {
    content = (
      <div>
        <div className="bg-background-muted rounded-md animate-pulse h-4 w-full mb-4" />
        <div className="flex gap-4 items-end">
          <div className="bg-background-muted rounded-md animate-pulse h-16 w-full" />
          <div className="bg-background-muted rounded-md animate-pulse h-6 w-8" />
        </div>
      </div>
    );
  }

  if (height === 'table') {
    content = (
      <div>
        <div className="bg-background-muted rounded-md animate-pulse h-4 w-1/2 mb-10" />
        <div className="h-10 mb-4 flex gap-4">
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/6" />
        </div>
        <div className="h-10 mb-4 flex gap-4">
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/6" />
        </div>
        <div className="h-10 mb-4 flex gap-4">
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/6" />
        </div>
        <div className="h-10 flex gap-4">
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/3" />
          <div className="bg-background-muted rounded-md animate-pulse h-full w-1/6" />
        </div>
      </div>
    );
  }

  return (
    <div className={cn(skeletonVariants({ width, height }), className)} {...props}>
      {content}
    </div>
  );
}

export { Skeleton };
