import { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { divIcon, LatLng, LeafletEventHandlerFnMap } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

const colorMap = {
  red: {
    primary: '#620E16',
    secondary: '#D70015',
  },
  green: {
    primary: '#0A7123',
    secondary: '#30D158',
  },
  blue: {
    primary: '#306794',
    secondary: '#409CFF',
  },
};

const getColor = (isSelected: boolean, isActive: boolean) => {
  if (isSelected) {
    return colorMap.blue;
  }

  return isActive ? colorMap.green : colorMap.red;
};

type Props = {
  position: Pick<LatLng, 'lat' | 'lng'>;
  isSelected?: boolean;
  isActive?: boolean;
  isFastCharge?: boolean;
  tooltipContent?: ReactNode;
  eventHandlers?: LeafletEventHandlerFnMap;
};

export const LocationMarker = ({
  position,
  isSelected,
  isActive,
  isFastCharge,
  tooltipContent,
  eventHandlers,
}: Props) => {
  const color = getColor(isSelected ?? true, isActive ?? false);
  const customIcon = divIcon({
    html: renderToStaticMarkup(
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 44C39.0457 44 48 35.0457 48 24C48 12.9543 39.0457 4 28 4C16.9543 4 8 12.9543 8 24C8 35.0457 16.9543 44 28 44Z"
          fill={color.secondary}
        />
        <path
          d="M27.9997 25.0003L14.4258 38.5742L27.9997 52.1481L42 38L27.9997 25.0003Z"
          fill={color.secondary}
        />
        <path
          d="M28 42C37.9411 42 46 33.9411 46 24C46 14.0589 37.9411 6 28 6C18.0589 6 10 14.0589 10 24C10 33.9411 18.0589 42 28 42Z"
          fill={color.primary}
        />
        <path d="M28.5 37L15.5527 37L28 49.7871L40.4453 37H28.5Z" fill={color.primary} />
        {isFastCharge ? (
          <path
            d="M21 26L25.9668 16H33.1034L29.5172 22H34L22.3448 34L25.0345 26H21Z"
            fill="white"
            stroke="white"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        ) : (
          <path
            d="M36.25 24C36.25 28.5563 32.5563 32.25 28 32.25C23.4437 32.25 19.75 28.5563 19.75 24C19.75 19.4437 23.4437 15.75 28 15.75C32.5563 15.75 36.25 19.4437 36.25 24Z"
            stroke="white"
            strokeWidth="1.5"
          />
        )}
      </svg>,
    ),
    iconAnchor: [28, 56],
    className: 'transform -translate-x-1/2 -translate-y-full',
  });

  return (
    <Marker
      ref={(ref) => isSelected && setTimeout(() => ref?.openPopup(), 0)}
      position={position}
      icon={customIcon}
      eventHandlers={eventHandlers}
    >
      {tooltipContent && (
        <Popup autoPan autoPanPaddingBottomRight={[180, 188]} maxWidth={180}>
          {tooltipContent}
        </Popup>
      )}
    </Marker>
  );
};
