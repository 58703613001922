import { zodResolver } from '@hookform/resolvers/zod';
import { AllowedCurrency } from '@ion-hq/utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { FormBottomBar } from '@/common/components/form-bottom-bar';
import { zodAllowedCurrency } from '@/common/utils/zod';
import { Input } from '@/shadcn/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shadcn/ui/select';
import { Table, TableHeader } from '@/shadcn/ui/table';

import { DEFAULT_CURRENCY, DEFAULT_UNIT, DEFAULT_VAT_PERCENTAGE } from '../tariff.const';

const tariffFormSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
  currency: zodAllowedCurrency,
  ratePerKiloWattHour: z.number().min(0),
  vatPercent: z.number().min(0),
});

export type TariffFormSchemaType = z.infer<typeof tariffFormSchema> & { currency: AllowedCurrency };

type Props = {
  isLoading: boolean;
  defaultValues: TariffFormSchemaType;
  tariffId?: number;
  onSubmit: SubmitHandler<TariffFormSchemaType>;
};

export const TariffForm = ({ isLoading, defaultValues, onSubmit, tariffId }: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, setValue, watch } = useForm<TariffFormSchemaType>({
    resolver: zodResolver(tariffFormSchema),
    values: defaultValues,
  });

  const cancelUrl = tariffId ? `/tariffs/${tariffId}` : '/tariffs';

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pb-32">
      <Table className="w-card mx-auto">
        <TableHeader title={t('TARIFF_FORM.HEADER')} />
        <div className="flex flex-col gap-4 px-6 pt-8 pb-6 font-figtree">
          <Input
            className="text-base"
            {...register('title')}
            placeholder={t('TARIFF_FORM.TITLE')}
            error={formState.errors.title?.message}
            disabled={isLoading}
          />
          <Input
            className="text-base"
            {...register('description')}
            placeholder={t('TARIFF_FORM.DESCRIPTION')}
            error={formState.errors.description?.message}
            disabled={isLoading}
          />
          <Select
            disabled={isLoading}
            defaultValue={watch('currency')}
            onValueChange={(value) => setValue('currency', value as AllowedCurrency)}
          >
            <SelectTrigger className="mb-6 text-base [&>span]:ml-auto [&>span]:mr-4">
              United Arab Emirates Dirham
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={DEFAULT_CURRENCY}>{DEFAULT_CURRENCY}</SelectItem>
            </SelectContent>
          </Select>
          <Select
            disabled={isLoading}
            defaultValue={watch('vatPercent').toString()}
            onValueChange={(value) => setValue('vatPercent', Number(value))}
          >
            <SelectTrigger className="mb-6 text-base">
              <SelectValue />
              <span className="ml-auto mr-4 text-lg text-muted">%</span>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={String(DEFAULT_VAT_PERCENTAGE)}>
                {DEFAULT_VAT_PERCENTAGE}
              </SelectItem>
            </SelectContent>
          </Select>
          <div className="flex gap-4 [&>div]:w-1/2">
            <Input
              {...register('ratePerKiloWattHour', { valueAsNumber: true })}
              placeholder={t('TARIFF_FORM.PRICE')}
              error={formState.errors.ratePerKiloWattHour?.message}
              disabled={isLoading}
              type="number"
              step="0.01"
              className="text-base [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
            <Select disabled={isLoading} defaultValue={'kWh'}>
              <SelectTrigger className="text-base">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={DEFAULT_UNIT}>{DEFAULT_UNIT}</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </Table>
      <FormBottomBar cancelLinkUrl={cancelUrl} isLoading={isLoading} />
    </form>
  );
};
