import { useTranslation } from 'react-i18next';

import SessionsIcon from '@/assets/icons/sessions.svg?react';
import { Widget } from '@/common/components/widget';

import { CpmsChargeTransactionStatisticsResponseDto } from '@/_api';

type Props = {
  data: CpmsChargeTransactionStatisticsResponseDto;
};

export const ChargeTransactionStatistics = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-start gap-4 pb-4">
      <div className="flex gap-4">
        <Widget
          title={t('CHARGE_TRANSACTIONS_PAGE.WIDGETS.AMOUNT')}
          content={data.allChargeTransactionsCount}
          reactIcon={<SessionsIcon />}
          contentProps={{ size: '5xl' }}
        />
      </div>
    </div>
  );
};
