import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import ArchiveIcon from '@/assets/icons/archive.svg?react';
import EditIcon from '@/assets/icons/edit.svg?react';
import { Icon } from '@/common/components/icon';
import { Modal } from '@/common/components/modal';
import { SettingsMenu } from '@/common/components/settings-menu';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';
import { useDeleteChargeSiteMutation } from '@/tanstack/mutations/charge-site/delete-charge-site.mutation';

import { ApplyTariffModal } from './apply-tariff-modal';

type Props = {
  chargeSiteId: number;
  chargeSiteTitle: string;
};

export const ChargeSiteSettingsMenu = ({ chargeSiteId, chargeSiteTitle }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: deleteChargeSite, isPending: isPendingDelete } =
    useDeleteChargeSiteMutation();

  const handleDelete = async () => {
    await deleteChargeSite(chargeSiteId);
    navigate('/locations');
  };

  return (
    <SettingsMenu title={t('CHARGE_SITE_PAGE.SETTINGS')}>
      <DropdownMenuItem asChild className="cursor-pointer">
        <Link to={`/locations/${chargeSiteId}/edit`}>
          <Icon reactIcon={<EditIcon />} className="mr-2" />
          {t('CHARGE_SITE_PAGE.EDIT')}
        </Link>
      </DropdownMenuItem>
      <Modal
        title={t('CHARGE_SITE_PAGE.ARCHIVE_CHARGE_SITE')}
        subtitle={t('CHARGE_SITE_PAGE.ARCHIVE_MODAL_SUBTITLE', { chargeSiteTitle })}
        variant="destructive"
        trigger={
          <DropdownMenuItem className="cursor-pointer" onSelect={(event) => event.preventDefault()}>
            <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
            {t('CHARGE_SITE_PAGE.ARCHIVE_CHARGE_SITE')}
          </DropdownMenuItem>
        }
        description={
          <Trans components={{ span: <span /> }}>
            {t('CHARGE_SITE_PAGE.ARCHIVE_MODAL_DESCRIPTION', { chargeSiteTitle })}
          </Trans>
        }
        renderConfirmButton={(close) => (
          <Button
            className="min-w-48"
            isLoading={isPendingDelete}
            onClick={async () => {
              await handleDelete();
              close();
            }}
          >
            {t('ARCHIVE')}
          </Button>
        )}
      />
      <br />
      <ApplyTariffModal chargeSiteId={chargeSiteId} chargeSiteTitle={chargeSiteTitle} />
    </SettingsMenu>
  );
};
