import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { formatTariffRatePrice } from '../../utils/utils';
import { TariffTableColumnType } from './tariff-table';

export const baseTariffTableColumns: ColumnDef<TariffTableColumnType>[] = [
  {
    accessorKey: ' name',
    header: () => <span className="w-80 pl-6 text-xs">{t('TARIFF_TABLE_HEADERS.NAME')}</span>,
    cell: (info) => {
      return (
        <div className="p-2 pl-6 w-80">
          <p className="font-medium text-lg leading-7">{info.row.original.title}</p>
          <p className="leading-4 text-muted font-figtree text-xs">
            {info.row.original.description}
          </p>
        </div>
      );
    },
  },
  {
    accessorKey: 'id',
    header: () => <span className="text-xs ml-8 w-20">{t('TARIFF_TABLE_HEADERS.ID')}</span>,
    cell: (info) => (
      <div className="text-xs ml-8 w-20">
        <Link
          to={`/tariffs/${info.row.original.id}`}
          className="hover:text-primary hover:underline"
        >
          {info.row.original.id}
        </Link>
      </div>
    ),
  },
  {
    accessorKey: 'price',
    header: () => <span className="text-xs ml-8 w-28">{t('TARIFF_TABLE_HEADERS.PRICE')}</span>,
    cell: (info) => {
      return <div className="text-xl ml-8 w-28">{formatTariffRatePrice(info.row.original)}</div>;
    },
  },
  {
    accessorKey: 'vat',
    header: () => <span className="text-xs ml-8 w-28">{t('TARIFF_TABLE_HEADERS.VAT')}</span>,
    cell: (info) => {
      return <div className="text-xl text-muted ml-8 w-28">{info.row.original.vatPercent}%</div>;
    },
  },
];
