import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TariffSvg from '@/assets/images/tariff.svg';
import { Button } from '@/shadcn/ui/button';

export const TariffListEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full my-16 gap-6">
      <img src={TariffSvg} alt="Tariff Icon" />

      <Link to="/tariffs/add">
        <Button variant="secondary">{t('TARIFF_LIST_PAGE.ADD_TARIFF')}</Button>
      </Link>
    </div>
  );
};
