import { isDefined } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageLayout } from '@/common/layouts/page-layout';
import { usePagination } from '@/common/pagination/pagination.hooks';
import { Button } from '@/shadcn/ui/button.tsx';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { useChargeSiteList } from '@/tanstack/queries/charge-site/use-charge-site-list.query';

import { ChargeSiteListPageContent } from './charge-site-list-content';
import { ChargeSitesStatistics } from './charge-sites-statistics';

export const ChargeSiteListPage = () => {
  const { t } = useTranslation();
  const { page } = usePagination();
  const { data } = useChargeSiteList({
    page,
    take: '100',
  });

  return (
    <PageLayout
      title={t('CHARGE_SITES_PAGE.PAGE_NAME')}
      rightSlot={
        <Link to="/locations/add">
          <Button variant="outline">{t('CHARGE_SITES_PAGE.ADD_NEW')}</Button>
        </Link>
      }
    >
      <ChargeSitesStatistics />
      {isDefined(data) ? <ChargeSiteListPageContent data={data} /> : <Skeleton height={'table'} />}
    </PageLayout>
  );
};
