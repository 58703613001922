import { isDefined } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ChargerIcon from '@/assets/icons/charger.svg?react';
import DescriptionIcon from '@/assets/icons/description.svg?react';
import GeolocationIcon from '@/assets/icons/geolocation.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import PercentIcon from '@/assets/icons/percent.svg?react';
import { LocationMarker } from '@/common/components/leaflet/location-marker';
import { MapComponent } from '@/common/components/leaflet/map';
import { Widget } from '@/common/components/widget';
import { PageLayout } from '@/common/layouts/page-layout';
import { getChargeSiteLatLng } from '@/common/utils/utils';
import { useChargeSiteData } from '@/tanstack/queries/charge-site/use-charge-site-data.query';

import { ChargeSiteChargePointList } from '../charge-site-list/charge-site-charge-point-list';
import { ChargeSitePageSkeleton } from './charge-site.page-skelleton';
import { ChargeSiteSettingsMenu } from './charge-site-settings-menu/charge-site-settings-menu';

export const ChargeSitePage = () => {
  const { t } = useTranslation();
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));

  if (isLoading || !isDefined(chargeSite)) {
    return <ChargeSitePageSkeleton />;
  }

  const chargeSiteCoordinates = getChargeSiteLatLng(chargeSite);

  return (
    <PageLayout
      title={chargeSite.title}
      rightSlot={
        <ChargeSiteSettingsMenu chargeSiteId={chargeSite.id} chargeSiteTitle={chargeSite.title} />
      }
      backUrl="/locations"
    >
      <div className="flex">
        <div className="w-2/5 flex-col gap-4">
          <div className="flex gap-4">
            <Widget
              fullWidth
              title={t('CHARGE_SITE_PAGE.ADDRESS')}
              content={<div className="min-h-12 flex items-end"> {chargeSite.address}</div>}
              reactIcon={<HomeIcon />}
            />
            <Widget
              fullWidth
              title={t('COORDINATES')}
              reactIcon={<GeolocationIcon />}
              content={
                <span>
                  {chargeSite.latitude}
                  <br />
                  {chargeSite.longitude}
                </span>
              }
            />
          </div>
          <div className="flex my-4">
            <Widget
              fullWidth
              reactIcon={<DescriptionIcon />}
              title={t('CHARGE_SITE_PAGE.DESCRIPTION')}
              content={chargeSite.description}
            />
          </div>
          <div className="flex gap-4">
            <Widget
              title={t('CHARGE_SITE_PAGE.CHARGE_POINTS')}
              fullWidth
              content={chargeSite.chargePoints.length.toString()}
              reactIcon={<ChargerIcon />}
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title={t('CHARGE_SITE_PAGE.AVERAGE_UTILIZATION_RATE')}
              content="100%"
              reactIcon={<PercentIcon />}
              contentProps={{ size: '5xl' }}
            />
          </div>
        </div>
        <div className="w-3/5 ml-4 z-0">
          <div className="h-full mb-4 ">
            <MapComponent center={chargeSiteCoordinates} closePopupOnClick={false}>
              <LocationMarker
                position={chargeSiteCoordinates}
                isSelected
                tooltipContent={<p>{chargeSite.title}</p>}
              />
            </MapComponent>
          </div>
        </div>
      </div>
      <div className="py-4">
        <ChargeSiteChargePointList data={chargeSite.chargePoints} chargeSiteId={chargeSite.id} />
      </div>
    </PageLayout>
  );
};
