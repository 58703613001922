import DownloadIcon from '@/assets/icons/downland.svg?react';
import { Icon } from '@/common/components/icon';
import { saveFile } from '@/common/utils/utils';
import { Button } from '@/shadcn/ui/button';
import { useDownloadInvoiceMutation } from '@/tanstack/queries/charge-transaction/download-invoice.mutation';

import { CpmsChargeTransactionListResponseDto } from '@/_api';

type DownloadInvoiceCellProps = {
  chargeTransaction: CpmsChargeTransactionListResponseDto['items'][number];
};

export const DownloadInvoiceCell = ({ chargeTransaction }: DownloadInvoiceCellProps) => {
  const { mutateAsync, isPending } = useDownloadInvoiceMutation();

  if (!chargeTransaction.chargingEndedAt) {
    return null;
  }

  const handleDownloadInvoice = async () => {
    const blob = await mutateAsync(String(chargeTransaction.id));

    if (blob) {
      saveFile(blob, `invoice-${chargeTransaction.id}.pdf`);
    }
  };

  return (
    <div className="mr-6 ml-auto">
      <Button
        variant="text"
        className="[&_svg]:size-5"
        onClick={handleDownloadInvoice}
        isLoading={isPending}
      >
        {!isPending && <Icon reactIcon={<DownloadIcon />} />}
      </Button>
    </div>
  );
};
