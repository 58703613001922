import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { applyTariff } from '@/_api';

type Variables = {
  chargeSiteId: number;
  tariffId: number;
};

export const useApplyTariffMutation = () => {
  return useMutation({
    mutationFn: ({ chargeSiteId, tariffId }: Variables) =>
      applyTariff({ path: { id: chargeSiteId }, body: { tariffId } }).then(({ data }) => data),

    onSettled: (_, __, { tariffId }) => {
      bulkInvalidateQueries([
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
        [QUERY_KEY.TARIFF_ITEM_WITH_CONNECTORS, tariffId],
        [QUERY_KEY.CHARGE_POINT_ITEM],
        [QUERY_KEY.CONNECTOR_ITEM],
      ]);
    },
  });
};
