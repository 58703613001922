import { ButtonHTMLAttributes, forwardRef } from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Spinner } from '@/common/components/spinner';
import { cn } from '@/common/utils/utils';

const buttonVariants = cva(
  'px-6 py-4 inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-8 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'py-3.5 border-2 bg-background shadow-sm hover:bg-muted hover:text-accent-foreground',
        secondary: 'py-3.5 border-2 border-background-muted text-primary shadow-sm hover:bg-muted',
        ghost: 'bg-primary-foreground text-muted cursor-default',
        text: '!px-0 py-3 underline-offset-4 hover:text-primary',
        textPrimary: '!px-0 py-3 underline-offset-4 text-primary hover:underline',
        textDestructive: '!px-0 py-3 underline-offset-4 text-destructive/70 hover:text-destructive',
      },
      size: {
        default: 'w-fit',
        fullWidth: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, children, isLoading, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant: disabled && variant !== 'text' ? 'ghost' : variant,
            size,
            className,
          }),
        )}
        disabled={disabled || isLoading}
        ref={ref}
        {...props}
      >
        <span className="leading-6 gap-2 font-semibold text-base capitalize flex items-center">
          {isLoading && <Spinner className="scale-75 h-6" />}
          {children}
        </span>
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
