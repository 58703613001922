import { PropsWithChildren, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg?react';
import { Icon } from '@/common/components/icon';

type Props = PropsWithChildren<{
  title: string;
  rightSlot?: ReactNode;
  backUrl?: string;
}>;

export const PageLayout = ({ children, rightSlot, title, backUrl }: Props) => {
  return (
    <div className={`relative h-screen bg-background`}>
      <div className="py-9 px-8 ml-44 flex items-center justify-between fixed left-6 right-0 z-aboveMap h-24 bg-background">
        <h1 className="text-3xl font-medium pl-3 flex items-center">
          {backUrl && (
            <Link to={backUrl} className="inline-block mr-4">
              <Icon reactIcon={<ArrowIcon height="24" width="24" />} className="rotate-180" />
            </Link>
          )}
          {title}
        </h1>
        <div>{rightSlot}</div>
      </div>
      <div
        className={`px-6 py-4 mt-24 w-full bg-background-muted h-[calc(100%-96px)] flex flex-col`}
      >
        {children}
      </div>
    </div>
  );
};
