import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { bootstrapFirebase } from '@/common/setup/firebase.setup';
import { bootstrapOpenApiClient } from '@/common/setup/open-api-client.setup';
import { bootstrapSentry } from '@/common/setup/sentry.setup';

import { App } from './app';

import './common/i18n/i18n';
import './index.css';

bootstrapSentry();
bootstrapFirebase();
bootstrapOpenApiClient();

createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
