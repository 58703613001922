import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { updateChargePoint, UpdateChargePointData } from '@/_api';

type Variables = {
  data: UpdateChargePointData['body']['connectors'];
  chargePointId: number;
  chargeSiteId: number;
};

export const useEditChargePointMutation = () => {
  return useMutation({
    mutationFn: ({ data, chargePointId }: Variables) =>
      updateChargePoint({ path: { id: chargePointId }, body: { connectors: data } }).then(
        ({ data }) => data,
      ),

    onSettled: (_, __, { data, chargePointId, chargeSiteId }) => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        [QUERY_KEY.CHARGE_SITE_LIST],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
        ...data.map((connector) => [QUERY_KEY.CONNECTOR_ITEM, connector.id]),
        ...data.map((connector) => [QUERY_KEY.TARIFF_ITEM_WITH_CONNECTORS, connector.tariffId]),
      ]);

      return bulkRefetchQueries([[QUERY_KEY.CHARGE_POINT_ITEM, chargePointId]]);
    },
  });
};
