import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';

import { TariffTable, TariffTableColumnType } from '@/common/components/tariff-table/tariff-table';
import { baseTariffTableColumns } from '@/common/components/tariff-table/tariff-table-columns';

import { TariffListEmpty } from './tariff-list-empty';

import { CpmsTariffWithDetailsResponseDto } from '@/_api';

type Props = {
  data: CpmsTariffWithDetailsResponseDto[];
};

const columns: ColumnDef<TariffTableColumnType>[] = [
  ...baseTariffTableColumns,
  {
    accessorKey: 'connectorCount',
    header: () => (
      <span className="text-xs text-center ml-auto mr-3 w-20">
        {t('TARIFF_LIST_PAGE.CONNECTOR_COUNT')}
      </span>
    ),
    cell: (info) => {
      return (
        <div className="text-center text-muted ml-auto mr-3 w-20">
          {(info.row.original as CpmsTariffWithDetailsResponseDto).connectorCount}
        </div>
      );
    },
  },
];

export const TariffListContent = ({ data }: Props) => {
  if (data.length === 0) {
    return <TariffListEmpty />;
  }

  return <TariffTable data={data} columns={columns} />;
};
