import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useUserContext } from '@/common/contexts/user/user.context';
import { SidebarLayout } from '@/common/layouts/sidebar-layout';

export const PrivateRoutesAllowedWrapper = () => {
  const { isUserLoggedIn } = useUserContext();
  const location = useLocation();

  if (!isUserLoggedIn) {
    const redirectTo = location.pathname + location.search;

    return <Navigate to={`/login?${new URLSearchParams({ redirectTo })}`} />;
  }

  return (
    <SidebarLayout>
      <Outlet />
    </SidebarLayout>
  );
};
