import { MouseEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArchiveIcon from '@/assets/icons/archive.svg?react';
import ExitIcon from '@/assets/icons/exit.svg?react';
import { Icon } from '@/common/components/icon';
import { Modal } from '@/common/components/modal';
import { SettingsMenu } from '@/common/components/settings-menu';
import { useToast } from '@/shadcn/hooks/use-toast';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';
import { useDeleteConnectorMutation } from '@/tanstack/mutations/connector/delete-connector.mutation';
import { useUnlockConnectorMutation } from '@/tanstack/mutations/connector/unlock-connector.mutation';

import { CpmsConnectorResponseDto } from '@/_api';

type Props = {
  connector: CpmsConnectorResponseDto;
};

export const ConnectorSettingsMenu = ({ connector }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: deleteConnector, isPending: isPendingDelete } = useDeleteConnectorMutation();
  const { mutateAsync: unlockConnector, isPending: isPendingUnlock } = useUnlockConnectorMutation();
  const { showErrorToast, showSuccessToast } = useToast();

  const handleDelete = async () => {
    await deleteConnector({
      chargeSiteId: connector.chargeSite.id,
      chargePointId: connector.chargePoint.id,
      connectorId: connector.id,
    });
    navigate(`/connectors/${connector.id}`);
  };

  const handleUnlock = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const response = await unlockConnector(connector.id);

    if (!response) {
      return;
    }

    switch (response.status) {
      case 'Unlocked': {
        showSuccessToast(t('CONNECTOR_PAGE.UNLOCK_SUCCESS', { connector }));
        break;
      }

      case 'UnlockFailed': {
        showSuccessToast(t('CONNECTOR_PAGE.UNLOCK_FAILED', { connector }));
        break;
      }

      case 'NotSupported': {
        showErrorToast(t('CONNECTOR_PAGE.UNLOCK_NOT_SUPPORTED', { connector }));
        break;
      }
    }
  };

  return (
    <SettingsMenu title={t('CONNECTOR_PAGE.SETTINGS')}>
      <DropdownMenuItem className="cursor-pointer" onClick={handleUnlock}>
        <Icon reactIcon={<ExitIcon />} className="mr-2 w-4 h-4" isLoading={isPendingUnlock} />
        {t('CONNECTOR_PAGE.UNLOCK')}
      </DropdownMenuItem>
      <Modal
        title={t('CONNECTOR_PAGE.ARCHIVE_CONNECTOR')}
        subtitle={t('CONNECTOR_PAGE.ARCHIVE_MODAL_SUBTITLE', { connector })}
        variant="destructive"
        trigger={
          <DropdownMenuItem className="cursor-pointer" onSelect={(event) => event.preventDefault()}>
            <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
            {t('CONNECTOR_PAGE.ARCHIVE_CONNECTOR')}
          </DropdownMenuItem>
        }
        description={<div>{t('CONNECTOR_PAGE.ARCHIVE_MODAL_DESCRIPTION', { connector })}</div>}
        renderConfirmButton={(close) => (
          <Button
            className="min-w-48"
            isLoading={isPendingDelete}
            onClick={async () => {
              await handleDelete();
              close();
            }}
          >
            {t('ARCHIVE')}
          </Button>
        )}
      />
    </SettingsMenu>
  );
};
