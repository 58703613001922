import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { t } from 'i18next';
import { Fragment } from 'react/jsx-runtime';

import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

import { CpmsConnectorResponseDto, CpmsTariffWithDetailsResponseDto } from '@/_api';

export type TariffTableColumnType =
  | CpmsTariffWithDetailsResponseDto
  | CpmsConnectorResponseDto['tariff'];

type Props = {
  data: TariffTableColumnType[];
  columns: ColumnDef<TariffTableColumnType>[];
};

export const TariffTable = ({ data, columns }: Props) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className="shrink-0">
      <TableHeader title={t('TARIFF_TABLE_HEADERS.TITLE')}>
        {table
          .getHeaderGroups()
          .map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <Fragment key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </Fragment>
            )),
          )}
      </TableHeader>
      {table.getRowModel().rows.map((row) => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <Fragment key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};
