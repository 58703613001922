import { Money } from '@ion-hq/utils';
import { type ClassValue, clsx } from 'clsx';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { CpmsChargeSiteItemResponseDto, StreamableFile } from '@/_api';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const cc = (condition: boolean, className: string): string =>
  condition ? ` ${className}` : '';

export const formatDate = (date: Date) => format(date, 'dd.MM.yyyy');
export const formatTime = (date: Date) => format(date, 'HH:mm');

export const formatDuration = (seconds: number) => {
  if (Math.sign(seconds) === -1) {
    throw new Error('seconds must be a positive number');
  }
  const formatedSeconds = (seconds % 60).toString().padStart(2, '0');
  const formatedMinutes = (Math.floor(seconds / 60) % 60).toString().padStart(2, '0');
  const formatedHours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');

  return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`;
};

export const formatTariffRatePrice = (tariff: { ratePerWh?: number | null; currency: string }) => {
  if (Math.sign(tariff.ratePerWh ?? 0) === -1) {
    throw new Error('ratePerWh must be a positive number');
  }
  const rateMoney = Money.fromPair((tariff.ratePerWh ?? 0) * 1000, tariff.currency);

  return `${rateMoney.toFormat('0.00')} ${rateMoney.getCurrency()}`;
};

export const saveFile = (blob: Blob | StreamableFile | MediaSource, fileName: string) => {
  const blobUrl = URL.createObjectURL(blob as Blob);

  const link = document.createElement('a');

  link.href = blobUrl;
  link.download = fileName;
  link.click();
};

export const getChargeSiteLatLng = (
  chargeSite: Pick<CpmsChargeSiteItemResponseDto, 'latitude' | 'longitude'>,
) => ({
  lat: chargeSite.latitude,
  lng: chargeSite.longitude,
});

export const getTariffPrice = (tariff: { ratePerWh: number | null; ratePerSec: number | null }) => {
  if (tariff.ratePerWh) {
    return tariff.ratePerWh * 1000;
  } else if (tariff.ratePerSec) {
    return tariff.ratePerSec * 60;
  }

  return 0;
};
