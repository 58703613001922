import { zodResolver } from '@hookform/resolvers/zod';
import { fromKiloWattToWatt, isDefined } from '@ion-hq/utils';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { ChargePointEditForm } from '@/common/components/charge-point-edit-form';
import { FormPageSkeleton } from '@/common/components/form.page-skeleton';
import { FormBottomBar } from '@/common/components/form-bottom-bar';
import { PageLayout } from '@/common/layouts/page-layout';
import { Button } from '@/shadcn/ui/button';
import { useAddChargePointsAndConnectorsMutation } from '@/tanstack/mutations/charge-site/add-charge-points-and-connectors.mutation';
import { useChargeSiteData } from '@/tanstack/queries/charge-site/use-charge-site-data.query';

import {
  editChargePointFormSchema,
  getDefaultConnector,
} from '../charge-point/edit-charge-point-page/edit-charge-point-form.utils';

const arrayOfEditChargePointFormSchema = z.object({
  chargePoints: z.array(editChargePointFormSchema),
});

export type ArrayEditChargePointFormType = z.infer<typeof arrayOfEditChargePointFormSchema>;

export const AddChargePointsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));
  const { mutateAsync, isPending } = useAddChargePointsAndConnectorsMutation();

  const formHandlers = useForm<ArrayEditChargePointFormType>({
    defaultValues: {
      chargePoints: [
        {
          id: null,
          connectors: [getDefaultConnector()],
        },
      ],
    },
    resolver: zodResolver(arrayOfEditChargePointFormSchema),
  });

  const { fields, append, remove } = useFieldArray<ArrayEditChargePointFormType>({
    name: 'chargePoints',
    rules: { minLength: 1 },
    control: formHandlers.control,
  });

  if (isLoading || !chargeSiteId) {
    return <FormPageSkeleton />;
  }

  const handleSubmit = formHandlers.handleSubmit(async (data) => {
    await mutateAsync({
      chargeSiteId: Number(chargeSiteId),
      data: {
        chargePoints: data.chargePoints.map((chargePoint) => ({
          connectors: chargePoint.connectors.map((connector) => ({
            ...connector,
            powerWt: fromKiloWattToWatt(connector.powerKiloWatt),
          })),
        })),
      },
    });
    navigate(`/locations/${chargeSiteId}`);
  });

  const handleAddChargePoint = () => append({ id: null, connectors: [getDefaultConnector()] });

  const lastChargePoint = chargeSite?.chargePoints.at(-1);
  const chargerReadableIdStartIndex =
    isDefined(lastChargePoint?.readableId) && Number.isFinite(Number(lastChargePoint.readableId))
      ? Number(lastChargePoint.readableId)
      : 0;

  return (
    <PageLayout
      title={t('ADD_CHARGE_POINTS_PAGE.PAGE_NAME')}
      backUrl={`/locations/${chargeSiteId}`}
    >
      <FormProvider {...formHandlers}>
        <form onSubmit={handleSubmit} className="p-2 pb-28">
          {fields.map((field, chargePointIndex) => {
            const chargePointReadableId = chargerReadableIdStartIndex + chargePointIndex + 1;

            return (
              <ChargePointEditForm
                key={field.id}
                chargePointIndex={chargePointIndex}
                chargePointReadableId={chargePointReadableId}
                deleteChargePoint={() => remove(chargePointIndex)}
                archivedConnectorsIds={[]}
              />
            );
          })}

          <Button type="button" size="fullWidth" variant="secondary" onClick={handleAddChargePoint}>
            {t('ADD_CHARGE_POINTS_PAGE.ADD_CHARGE_POINT')}
          </Button>

          <FormBottomBar
            cancelLinkUrl={`/locations/${chargeSiteId}`}
            isLoading={isPending || isLoading}
          />
        </form>
      </FormProvider>
    </PageLayout>
  );
};
