import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/common/components/spinner';
import { UserContext } from '@/common/contexts/user/user.context';
import { useToast } from '@/shadcn/hooks/use-toast';

import { cpmsGetMe } from '@/_api';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isFirebaseLoaded, setIsFirebaseLoaded] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isAuthInProgress, setIsAuthInProgress] = useState(true);
  const isAuthorizingRef = useRef(false);
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  useEffect(() => {
    const redirectTo = new URLSearchParams(window.location.search).get('redirectTo') ?? '/';
    const handleLogout = () => {
      getAuth().signOut();
      navigate(`/login?${new URLSearchParams({ redirectTo })}`);
    };

    onAuthStateChanged(getAuth(), (user) => {
      if (isAuthorizingRef.current) {
        return;
      }
      setIsFirebaseLoaded(true);

      if (user) {
        setIsAuthInProgress(true);
        cpmsGetMe()
          .then(() => {
            setIsUserLoggedIn(true);

            if (window.location.pathname === '/login') {
              navigate(redirectTo);
            }
          })
          .catch((error) => {
            if (error.status === 401) {
              handleLogout();
            } else {
              showErrorToast(t('ERRORS.BACKEND_SHORTCODES.DEFAULT'));
            }
          })
          .finally(() => {
            setIsAuthInProgress(false);
            isAuthorizingRef.current = false;
          });
      } else {
        setIsUserLoggedIn(false);

        const href = window.location.href;
        const email = window.localStorage.getItem('emailForSignIn');

        if (!email || (email && !isSignInWithEmailLink(getAuth(), href))) {
          return setIsAuthInProgress(false);
        }

        signInWithEmailLink(getAuth(), email, href)
          .catch(() => handleLogout())
          .finally(() => window.localStorage.removeItem('emailForSignIn'));
      }
    });
  }, [navigate, showErrorToast]);

  const contextValue = useMemo(() => ({ isUserLoggedIn }), [isUserLoggedIn]);

  if (!isFirebaseLoaded || isAuthInProgress) {
    return <Spinner className="h-screen w-screen" />;
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
