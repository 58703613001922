import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import TariffsIcon from '@/assets/icons/tariffs.svg?react';
import { Icon } from '@/common/components/icon';
import { Modal } from '@/common/components/modal';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';
import { useApplyTariffMutation } from '@/tanstack/mutations/charge-site/apply-tariff.mutation';

import { ApplyTariffModalContent } from './apply-tariff-modal-content';

type Props = {
  chargeSiteId: number;
  chargeSiteTitle: string;
};

export const ApplyTariffModal = ({ chargeSiteId, chargeSiteTitle }: Props) => {
  const { t } = useTranslation();

  const { mutateAsync: applyTariff, isPending: isPendingApplyTariff } = useApplyTariffMutation();

  const [selectedTariffId, setSelectedTariffId] = useState<number | null>(null);

  return (
    <Modal
      title={t('CHARGE_SITE_PAGE.APPLY_TARIFF_MODAL_TITLE')}
      variant="default"
      trigger={
        <DropdownMenuItem className="cursor-pointer" onSelect={(event) => event.preventDefault()}>
          <Icon reactIcon={<TariffsIcon width="16" height="16" />} className="mr-2" />
          {t('CHARGE_SITE_PAGE.APPLY_TARIFF')}
        </DropdownMenuItem>
      }
      description={
        <ApplyTariffModalContent
          chargeSiteTitle={chargeSiteTitle}
          selectedTariffId={selectedTariffId}
          setSelectedTariffId={setSelectedTariffId}
        />
      }
      renderConfirmButton={(close) => (
        <Button
          className="min-w-48"
          disabled={!selectedTariffId}
          isLoading={isPendingApplyTariff}
          onClick={async () => {
            if (selectedTariffId) {
              await applyTariff({ chargeSiteId, tariffId: selectedTariffId });
              close();
            }
          }}
        >
          {t('APPLY')}
        </Button>
      )}
      onClose={() => setSelectedTariffId(null)}
    />
  );
};
