import { useMemo } from 'react';

import { AllowedCurrency, Money } from '@ion-hq/utils';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { t } from 'i18next';
import { latLngBounds, LatLngExpression } from 'leaflet';
import { Fragment } from 'react/jsx-runtime';
import { useParams } from 'react-router-dom';

import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import DescriptionIcon from '@/assets/icons/description.svg?react';
import { FitBounds } from '@/common/components/leaflet/fit-bounds';
import { LocationMarker } from '@/common/components/leaflet/location-marker';
import { MapComponent } from '@/common/components/leaflet/map';
import { Widget } from '@/common/components/widget';
import { PageLayout } from '@/common/layouts/page-layout';
import { getTariffPrice } from '@/common/utils/utils';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';
import { useTariff } from '@/tanstack/queries/tariff/use-tariff.query';

import { connectorListColumns } from './connector-list-columns';
import { TariffPageSkeleton } from './tariff.page-skeleton';
import { TariffSettingsMenu } from './tariff-settings-menu';

export const TariffPage = () => {
  const { tariffId } = useParams();
  const { data } = useTariff(Number(tariffId));

  const table = useReactTable({
    data: data?.connectors ?? [],
    columns: connectorListColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [locationLatLngPairs, mapBounds] = useMemo(() => {
    if (!data) {
      return [undefined, undefined];
    }

    const locationsCoordinates = [
      ...new Set(
        data.connectors.map(
          ({ chargePoint: { chargeSite } }) => `${chargeSite.latitude},${chargeSite.longitude}`,
        ),
      ),
    ];
    const latLngPairs = locationsCoordinates.map((latLng) => latLng.split(',').map(Number));

    const mapBounds = latLngBounds(latLngPairs as LatLngExpression[]);

    return [latLngPairs, mapBounds];
  }, [data]);

  if (!data || !locationLatLngPairs) {
    return <TariffPageSkeleton />;
  }

  const rateMoney = new Money(getTariffPrice(data), data.currency as AllowedCurrency);

  return (
    <PageLayout
      title={data.title}
      rightSlot={<TariffSettingsMenu tariffId={data.id} />}
      backUrl="/tariffs"
    >
      <div className="flex gap-4">
        <div className="w-2/5 flex flex-col gap-4">
          <Widget
            title={t('TARIFF_PAGE.ID')}
            content={data.id}
            contentProps={{ size: '2xl' }}
            reactIcon={<BarcodeIcon />}
          />
          <Widget
            title={t('TARIFF_PAGE.DESCRIPTION')}
            content={data.description}
            reactIcon={<DescriptionIcon />}
          />
          <Table>
            <TableHeader title={t('TARIFF_PAGE.TARIFF_INFO.TITLE')}>
              <div className="text-xs ml-6 w-32">{t('TARIFF_PAGE.TARIFF_INFO.HEADERS.RATE')}</div>
              <div className="text-xs ml-auto w-16">{t('TARIFF_PAGE.TARIFF_INFO.HEADERS.VAT')}</div>
            </TableHeader>
            <TableRow>
              <div className="text-xl ml-6 w-32">
                {rateMoney.toFormat('0.00')} {rateMoney.getCurrency()}
              </div>
              <div className="text-xl text-muted ml-auto w-16">{data.vatPercent}%</div>
            </TableRow>
          </Table>
        </div>
        <div className="w-3/5">
          <MapComponent>
            {locationLatLngPairs.map((latLng) => (
              <LocationMarker
                key={latLng.join(',')}
                position={{ lat: latLng[0], lng: latLng[1] }}
              />
            ))}
            <FitBounds bounds={mapBounds} />
          </MapComponent>
        </div>
      </div>
      <Table className="mt-4 shrink-0">
        <TableHeader title={t('TARIFF_PAGE.CONNECTOR_TABLE.TITLE')}>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              )),
            )}
        </TableHeader>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Fragment>
            ))}
          </TableRow>
        ))}
      </Table>
      <div className="pb-4" />
    </PageLayout>
  );
};
