import { isDefined } from '@ion-hq/utils';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ArrayEditChargePointFormType } from '@/pages/add-chargers/add-chargers';
import {
  EditChargePointFormType,
  getDefaultConnector,
} from '@/pages/charge-point/edit-charge-point-page/edit-charge-point-form.utils';
import { Button } from '@/shadcn/ui/button';

import { FormConnectorFields } from './connector-edit-form';
import { DeleteChargePointModal } from './delete-charge-point-modal';
import { DeleteConnectorModal } from './delete-connector-modal';

type Props = {
  /** chargePointIndex - if editing one charger - we won't have this index */
  chargePointIndex?: number;
  chargePointReadableId: number;
  archivedConnectorsIds: number[];
  deleteChargePoint?: () => void;
};

export const ChargePointEditForm = ({
  chargePointReadableId,
  chargePointIndex,
  archivedConnectorsIds,
  deleteChargePoint,
}: Props) => {
  const hasChargePointIndex = isDefined(chargePointIndex);

  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<
    EditChargePointFormType | ArrayEditChargePointFormType
  >({
    name: hasChargePointIndex
      ? (`chargePoints.${chargePointIndex}.connectors` as const)
      : 'connectors',
    rules: { minLength: 1 },
  });

  const handleAddDefaultConnector = () => append(getDefaultConnector());

  return (
    <div className="border-muted border-1.5 rounded-md mb-8 overflow-hidden">
      <div className="font-bold text-lg px-6 py-4 flex justify-between mb-6 items-center bg-background">
        {t('CHARGE_POINT_FORM.CHARGE_POINT')} #{chargePointReadableId}
        {isDefined(deleteChargePoint) && (
          <DeleteChargePointModal
            chargePointReadableId={chargePointReadableId}
            deleteChargePoint={deleteChargePoint}
          />
        )}
      </div>
      {fields.map((field, connectorIndex) => {
        const connectorReadableId = `${chargePointReadableId}.${connectorIndex + 1}`;

        return (
          <FormConnectorFields
            key={field.id}
            connectorIndex={connectorIndex}
            chargePointIndex={chargePointIndex}
            connectorReadableId={connectorReadableId}
            archivedConnectorsIds={archivedConnectorsIds}
            actionsSlot={
              <>
                {hasChargePointIndex && (
                  <DeleteConnectorModal
                    connectorIndex={connectorIndex}
                    connectorReadableId={connectorReadableId}
                    remove={() => remove(connectorIndex)}
                  />
                )}
                {connectorIndex === fields.length - 1 && (
                  <Button type="button" className="h-14 w-14" onClick={handleAddDefaultConnector}>
                    +
                  </Button>
                )}
              </>
            }
          />
        );
      })}
    </div>
  );
};
