import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EditIcon from '@/assets/icons/edit.svg?react';
import { Icon } from '@/common/components/icon';
import { SettingsMenu } from '@/common/components/settings-menu';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';

type Props = {
  tariffId: number;
};

export const TariffSettingsMenu = ({ tariffId }: Props) => {
  const { t } = useTranslation();

  return (
    <SettingsMenu title={t('TARIFF_PAGE.SETTINGS')}>
      <DropdownMenuItem asChild className="cursor-pointer">
        <Link to={`/tariffs/${tariffId}/edit`}>
          <Icon reactIcon={<EditIcon />} className="mr-2" />
          {t('TARIFF_PAGE.EDIT')}
        </Link>
      </DropdownMenuItem>
    </SettingsMenu>
  );
};
