import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import { ErrorLayout } from '@/common/layouts/error-layout';
import { LoginLayout } from '@/common/layouts/login-layout';
import { AppReadyWrapper } from '@/common/root/app-ready-wrapper';
import { PrivateRoutesAllowedWrapper } from '@/common/root/private-routes-allowed-wrapper.tsx';

import { AddChargePointsPage } from './pages/add-chargers/add-chargers';
import { ChargePointPage } from './pages/charge-point/charge-point-page/charge-point.page';
import { EditChargePointPage } from './pages/charge-point/edit-charge-point-page/edit-charge-point.page';
import { AddChargeSitePage } from './pages/charge-site/add-charge-site-page/add-charge-site.page';
import { ChargeSiteListPage } from './pages/charge-site/charge-site-list/charge-site-list.page';
import { ChargeSitePage } from './pages/charge-site/charge-site-page/charge-site.page';
import { EditChargeSitePage } from './pages/charge-site/edit-charge-site-page/edit-charge-site.page';
import { ChargeTransactionsListPage } from './pages/charge-transaction/charge-transaction-list/charge-transactions-list.page';
import { ChargeTransactionPage } from './pages/charge-transaction/charge-trasaction-page/charge-transaction.page';
import { ConnectorPage } from './pages/connector/connector.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { EmailLinkLoginPage } from './pages/login/email-link-login-page/email-link-login.page';
import { EmailLinkSentPage } from './pages/login/email-link-sent.page';
import { UserNotInvitedPagePage } from './pages/login/user-not-invited.page';
import { AddTariffPage } from './pages/tariff/add-tariff/add-tariff.page';
import { EditTariffPage } from './pages/tariff/edit-tariff/edit-tariff.page';
import { TariffListPage } from './pages/tariff/tariff-list/tariff-list.page';
import { TariffPage } from './pages/tariff/tariff-page/tariff-page';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <AppReadyWrapper />,
    children: [
      {
        path: '/',
        element: <PrivateRoutesAllowedWrapper />,
        children: [
          {
            path: '/',
            element: <DashboardPage />,
          },
          {
            path: '/locations',
            element: <ChargeSiteListPage />,
          },
          {
            path: '/locations/add',
            element: <AddChargeSitePage />,
          },
          {
            path: '/locations/:chargeSiteId',
            element: <ChargeSitePage />,
          },
          {
            path: '/locations/:chargeSiteId/edit',
            element: <EditChargeSitePage />,
          },
          {
            path: '/locations/:chargeSiteId/add-chargers',
            element: <AddChargePointsPage />,
          },
          {
            path: '/chargers/:chargePointId',
            element: <ChargePointPage />,
          },
          {
            path: '/chargers/:chargePointId/edit',
            element: <EditChargePointPage />,
          },
          {
            path: '/connectors/:connectorId',
            element: <ConnectorPage />,
          },
          {
            path: '/tariffs',
            element: <TariffListPage />,
          },
          {
            path: '/tariffs/add',
            element: <AddTariffPage />,
          },
          {
            path: '/tariffs/:tariffId',
            element: <TariffPage />,
          },
          {
            path: '/tariffs/:tariffId/edit',
            element: <EditTariffPage />,
          },
          {
            path: '/sessions',
            element: <ChargeTransactionsListPage />,
          },
          {
            path: '/sessions/:chargeTransactionId',
            element: <ChargeTransactionPage />,
          },
        ],
      },
      {
        path: '/login',
        element: <LoginLayout />,
        children: [
          {
            path: '/login',
            element: <EmailLinkLoginPage />,
          },
          {
            path: '/login/sent',
            element: <EmailLinkSentPage />,
          },
          {
            path: '/login/not-invited',
            element: <UserNotInvitedPagePage />,
          },
        ],
      },
    ],
  },
  { path: '*', element: <ErrorLayout /> },
]);
