import { useTranslation } from 'react-i18next';

import ChargePointIcon from '@/assets/icons/charger.svg?react';
import TariffsIcon from '@/assets/icons/tariffs.svg?react';
import { Widget } from '@/common/components/widget';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { useTariffStatistics } from '@/tanstack/queries/tariff/use-tariffs-statistics.query';

export const TariffStatistics = () => {
  const { t } = useTranslation();
  const { data } = useTariffStatistics();

  return (
    <div className="flex gap-4 mb-4">
      {data ? (
        <>
          <Widget
            title={t('TARIFF_LIST_PAGE.TARIFF_COUNT')}
            content={data.tariffCount}
            contentProps={{ size: '5xl' }}
            reactIcon={<TariffsIcon />}
          />
          <Widget
            title={t('TARIFF_LIST_PAGE.CONNECTOR_COUNT')}
            content={data.connectorCount}
            contentProps={{ size: '5xl' }}
            reactIcon={<ChargePointIcon />}
          />
        </>
      ) : (
        <>
          <Skeleton width="widget" height="widget" />
          <Skeleton width="widget" height="widget" />
        </>
      )}
    </div>
  );
};
