import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { deleteChargeSite } from '@/_api';

export const useDeleteChargeSiteMutation = () => {
  return useMutation({
    mutationFn: (chargeSiteId: number) =>
      deleteChargeSite({ path: { id: chargeSiteId } }).then(({ data }) => data),

    onSettled: () => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
      ]);

      return bulkRefetchQueries([[QUERY_KEY.CHARGE_SITE_LIST], [QUERY_KEY.CHARGE_SITE_STATISTIC]]);
    },
  });
};
