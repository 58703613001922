import { useMutation } from '@tanstack/react-query';

import { ChargePointResetBodyRequestDto, resetChargePoint } from '@/_api';

type Variables = {
  chargePointId: number;
  resetType: ChargePointResetBodyRequestDto['resetType'];
};

export const useRestartChargePointMutation = () => {
  return useMutation({
    mutationFn: ({ chargePointId, resetType }: Variables) =>
      resetChargePoint({ path: { id: chargePointId }, body: { resetType } }).then(
        ({ data }) => data,
      ),
  });
};
