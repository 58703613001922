import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { restoreChargePoint } from '@/_api';

type Variables = {
  chargeSiteId: number;
  chargePointId: number;
  connectorIds: number[];
  refetch: 'chargeSite' | 'chargeSiteList' | 'none';
};

export const useRestoreChargePointMutation = () => {
  return useMutation({
    mutationFn: ({ chargePointId }: Variables) =>
      restoreChargePoint({ path: { id: chargePointId } }).then(({ data }) => data),

    onSettled: (_, __, { chargeSiteId, chargePointId, connectorIds, refetch }) => {
      const invalidateQueries = [
        [QUERY_KEY.CHARGE_SITE_STATISTIC],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
        ...connectorIds.map((connectorId) => [QUERY_KEY.CONNECTOR_ITEM, connectorId]),
      ];
      const refetchQueries = [[QUERY_KEY.CHARGE_POINT_ITEM, chargePointId]];

      switch (refetch) {
        case 'chargeSite': {
          refetchQueries.push([QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId]);
          invalidateQueries.push([QUERY_KEY.CHARGE_SITE_LIST]);

          break;
        }

        case 'chargeSiteList': {
          refetchQueries.push([QUERY_KEY.CHARGE_SITE_LIST]);
          invalidateQueries.push([QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId]);

          break;
        }

        case 'none': {
          invalidateQueries.push(
            [QUERY_KEY.CHARGE_SITE_LIST],
            [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
          );

          break;
        }
      }

      bulkInvalidateQueries(invalidateQueries);

      return bulkRefetchQueries(refetchQueries);
    },
  });
};
