import { cpmsChargeSiteConnectorsStatsQueryFn } from '../queries/charge-site/use-charge-site-connectors-stats.query';
import { queryClient } from '../query-client';
import { QUERY_KEY } from '../query-keys.const';

export const chargeSiteConnectorsStatsPrefetch = async (chargeSiteId: number) => {
  queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_CONNECTOR_STATS, chargeSiteId],
    queryFn: cpmsChargeSiteConnectorsStatsQueryFn,
  });
};
