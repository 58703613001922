import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { deleteConnector } from '@/_api';

type Variables = {
  chargeSiteId: number;
  chargePointId: number;
  connectorId: number;
};

export const useDeleteConnectorMutation = () => {
  return useMutation({
    mutationFn: ({ connectorId }: Variables) =>
      deleteConnector({ path: { id: connectorId } }).then(({ data }) => data),

    onSettled: (_, __, { connectorId, chargePointId, chargeSiteId }) => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_POINT_ITEM, chargePointId],
        [QUERY_KEY.CHARGE_SITE_LIST],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [(QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId)],
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
      ]);

      return bulkRefetchQueries([[QUERY_KEY.CONNECTOR_ITEM, connectorId]]);
    },
  });
};
