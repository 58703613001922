import { isDefined } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageLayout } from '@/common/layouts/page-layout';
import { Button } from '@/shadcn/ui/button';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { useTariffsWithDetailsData } from '@/tanstack/queries/tariff/use-tariffs-with-details.query';

import { TariffListContent } from './tariff-list-content';
import { TariffStatistics } from './tariff-statistics';

export const TariffListPage = () => {
  const { t } = useTranslation();
  const { data } = useTariffsWithDetailsData();

  return (
    <PageLayout
      title={t('TARIFF_LIST_PAGE.PAGE_NAME')}
      rightSlot={
        <Link to="/tariffs/add">
          <Button variant="outline">Add New</Button>
        </Link>
      }
    >
      <TariffStatistics />
      {isDefined(data) ? <TariffListContent data={data} /> : <Skeleton height={'table'} />}
      <div className="pb-4" />
    </PageLayout>
  );
};
