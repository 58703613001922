import { CpmsChargeSiteListResponseDto } from '@/_api';

type PageElement = number | '...';

export type PaginationProperties = Omit<CpmsChargeSiteListResponseDto, 'items'>;

export const generatePageElements = (currentPage: number, totalPages: number): PageElement[] => {
  const isLessThan5Pages = totalPages < 5;
  const isNearStart = currentPage === 1 || currentPage === 2;
  const isNearEnd = currentPage === totalPages || currentPage === totalPages - 1;

  if (isLessThan5Pages) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  if (isNearStart || isNearEnd) {
    return [1, 2, '...', totalPages - 1, totalPages];
  }

  return [1, '...', currentPage, '...', totalPages];
};
