import { useTranslation } from 'react-i18next';

import { DisabledSettingsButton } from '@/common/components/disabled-settings-button';
import { PageLayout } from '@/common/layouts/page-layout';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const ConnectorPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t('SKELETON.LOADING')}
      rightSlot={<DisabledSettingsButton title={t('CONNECTOR_PAGE.SETTINGS')} />}
    >
      <div className="flex mb-4">
        <div className="w-2/5 flex-col gap-4">
          <div className="flex gap-4">
            <Skeleton height="widget" />
            <Skeleton height="widget" />
          </div>

          <div className="flex mt-4 gap-4">
            <Skeleton height="widget" />
            <Skeleton height="widget" />
          </div>
          <div className="flex mt-4 gap-4">
            <Skeleton height="widget" />
            <Skeleton height="widget" />
          </div>
        </div>
        <div className="w-3/5 ml-4 z-0">
          <div className="h-full mb-4 ">
            <Skeleton />
          </div>
        </div>
      </div>
      <Skeleton height="tableRow" />
    </PageLayout>
  );
};
