import { t } from 'i18next';
import { z } from 'zod';

import { CONNECTOR_TYPES, MAX_CONNECTOR_POWER_KWT } from '@/common/constants/const';

export const editChargePointFormSchema = z.object({
  id: z.number().positive().int().nullable(),
  connectors: z.array(
    z.object({
      id: z.number().positive().int().nullable(),
      powerKiloWatt: z
        .number({ message: t('ERRORS.ZOD.NUMBER') })
        .positive()
        .int()
        .max(MAX_CONNECTOR_POWER_KWT),
      type: z.nativeEnum(CONNECTOR_TYPES),
      tariffId: z.number(),
    }),
  ),
});

export const getDefaultConnector = () =>
  ({ id: null, powerKiloWatt: 0, type: 1, tariffId: 1 }) as const;

export type EditChargePointFormType = z.infer<typeof editChargePointFormSchema>;
