import { cva, type VariantProps } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';

import { cn } from '@/common/utils/utils';

const variant = {
  ARCHIVED: 'bg-muted-background text-background',
  PAID: 'bg-success-background text-success-foreground',
  UNPAID: 'bg-destructive-background text-destructive',
  CHARGING: 'bg-primary-semilight text-primary',
} as const;

export type BadgeVariant = keyof typeof variant;

const badgeVariants = cva(
  'rounded-xs px-2 py-1 text-xs font-semibold font-figtree capitalize self-center max-w-max',
  { variants: { variant } },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  readonly variant: BadgeVariant;
}

function Badge({ className, variant, ...props }: BadgeProps) {
  const { t } = useTranslation();

  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props}>
      {t(`BADGE.${variant}`)}
    </div>
  );
}

export { Badge };
