import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsTariff } from '@/_api';

export const useTariff = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.TARIFF_ITEM_WITH_CONNECTORS, id],
    queryFn: () => getCpmsTariff({ path: { id } }).then(({ data }) => data),
  });
};
