import { useEffect } from 'react';

import { getAuth } from 'firebase/auth';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import NotInvitedEmailSvg from '@/assets/images/not-invited-email.svg';

export const UserNotInvitedPagePage = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const email = params.get('email') ?? 'email';

  useEffect(() => {
    getAuth()
      .signOut()
      .then(() => {
        console.log('User signed out');
        window.localStorage.removeItem('emailForSignIn');
      });
  }, []);

  return (
    <>
      <img src={NotInvitedEmailSvg} alt="Confirmation Icon" className="mx-auto mt-10" />
      <div className="text-muted mt-10">
        <Trans components={{ span: <span /> }}>
          {t('USER_NOT_INVITED_PAGE.HEADER', { email })}
        </Trans>
      </div>
      <div className="text-muted my-10">
        {/* eslint-disable-next-line sonarjs/anchor-is-valid, sonarjs/anchor-has-content*/}
        <Trans components={{ a: <a /> }}>{t('USER_NOT_INVITED_PAGE.CONTENT')}</Trans>
      </div>
    </>
  );
};
