import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '@/common/layouts/page-layout';
import { useCreateTariffMutation } from '@/tanstack/mutations/tariff/create-tariff.mutation';

import { DEFAULT_CURRENCY, DEFAULT_VAT_PERCENTAGE } from '../tariff.const';
import { TariffForm, TariffFormSchemaType } from './tariff-form';

const defaultValues = {
  title: '',
  description: '',
  currency: DEFAULT_CURRENCY,
  vatPercent: DEFAULT_VAT_PERCENTAGE,
  ratePerKiloWattHour: 0,
};

export const AddTariffPage = () => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useCreateTariffMutation();
  const navigate = useNavigate();

  const handleSubmit = async (formData: TariffFormSchemaType) => {
    await mutateAsync({
      currency: formData.currency,
      description: formData.description ?? null,
      ratePerWh: Number(((formData.ratePerKiloWattHour / 1000) * 100).toFixed(2)),
      title: formData.title,
      vatPercent: formData.vatPercent,
    });
    navigate('/tariffs');
  };

  return (
    <PageLayout title={t('ADD_TARIFF_PAGE.TITLE')}>
      <TariffForm defaultValues={defaultValues} isLoading={isPending} onSubmit={handleSubmit} />
    </PageLayout>
  );
};
