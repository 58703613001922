import { isDefined, Money } from '@ion-hq/utils';
import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import UserIcon from '@/assets/icons/user.svg?react';
import { Icon } from '@/common/components/icon';
import { formatDate, formatTime } from '@/common/utils/utils';

import { DownloadInvoiceCell } from './download-invoice-cell';

import { CpmsChargeTransactionListResponseDto } from '@/_api';

export const chargeTransactionsListColumns: ColumnDef<
  CpmsChargeTransactionListResponseDto['items'][number]
>[] = [
  {
    accessorKey: 'date',
    header: () => (
      <span className="text-xs pl-6 w-32">{t('CHARGE_TRANSACTIONS_PAGE.TABLE.HEADERS.DATE')}</span>
    ),
    cell: (info) => (
      <span className="text-xs pl-6 w-32 font-figtree">
        {info.row.original.chargingEndedAt && formatDate(info.row.original.chargingEndedAt)}
      </span>
    ),
  },
  {
    accessorKey: 'time',
    header: () => (
      <span className="text-xs w-20">{t('CHARGE_TRANSACTIONS_PAGE.TABLE.HEADERS.TIME')}</span>
    ),
    cell: (info) => {
      return (
        <span className="text-xs w-20 font-figtree">
          {info.row.original.chargingEndedAt && formatTime(info.row.original.chargingEndedAt)}
        </span>
      );
    },
  },
  {
    accessorKey: 'user',
    header: () => (
      <span className="text-xs w-60">{t('CHARGE_TRANSACTIONS_PAGE.TABLE.HEADERS.USER')}</span>
    ),
    cell: (info) => (
      <div className="w-60 text-left font-medium text-lg leading-7 flex gap-1 items-center">
        <Icon className="text-muted" reactIcon={<UserIcon width={20} height={20} />} />
        {info.row.original.user.phoneNumber}
      </div>
    ),
  },
  {
    accessorKey: 'chargeSite',
    header: () => (
      <span className="text-xs w-80">
        {t('CHARGE_TRANSACTIONS_PAGE.TABLE.HEADERS.CHARGE_SITE')}
      </span>
    ),
    cell: (info) => (
      <div className="w-80">
        <div className="text-left font-medium text-lg leading-7 text-ellipsis text-nowrap overflow-hidden">
          {info.row.original.chargePoint.chargeSite.title}
        </div>
        <div className="leading-4 text-muted text-xs text-ellipsis text-nowrap overflow-hidden">
          {info.row.original.chargePoint.chargeSite.address}
        </div>
      </div>
    ),
  },
  {
    accessorKey: 'id',
    header: () => (
      <span className="text-xs pl-3 w-32">
        {t('CHARGE_TRANSACTIONS_PAGE.TABLE.HEADERS.TRANSACTION_ID')}
      </span>
    ),
    cell: (info) =>
      info.row.original.chargingEndedAt ? (
        <Link
          to={`/sessions/${info.row.original.id}`}
          className="text-xs pl-3 w-32 font-figtree hover:underline hover:text-primary"
        >
          {info.row.original.id}
        </Link>
      ) : (
        <span className="text-xs pl-3 w-40 font-figtree">{info.row.original.id}</span>
      ),
  },
  {
    accessorKey: 'totalAmount',
    header: () => (
      <span className="text-xs p-2 w-40 text-center">
        {t('CHARGE_TRANSACTIONS_PAGE.TABLE.HEADERS.TOTAL_AMOUNT')}
      </span>
    ),
    cell: (info) => {
      const { calculatedPayment, finalPayment, currency } = info.row.original;
      const payment = isDefined(calculatedPayment)
        ? Money.fromPair(finalPayment ?? calculatedPayment, currency)
        : null;

      return (
        <span className="text-lg w-40 text-muted text-center">
          {payment ? (
            <>
              {payment.toFormat('0.00')}&nbsp;{payment.getCurrency()}
            </>
          ) : null}
        </span>
      );
    },
  },
  {
    accessorKey: 'downloadInvoice',
    header: () => null,
    cell: (info) => <DownloadInvoiceCell chargeTransaction={info.row.original} />,
  },
];
