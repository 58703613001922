import { fromWattToKiloWatt } from '@ion-hq/utils';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import GeolocationIcon from '@/assets/icons/geolocation.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import LightningIcon from '@/assets/icons/lightning.svg?react';
import { ConnectorIcon } from '@/common/components/connector-icon';
import { LocationMarker } from '@/common/components/leaflet/location-marker';
import { MapComponent } from '@/common/components/leaflet/map';
import { TariffTable } from '@/common/components/tariff-table/tariff-table';
import { baseTariffTableColumns } from '@/common/components/tariff-table/tariff-table-columns';
import { Widget } from '@/common/components/widget';
import { PageLayout } from '@/common/layouts/page-layout';
import { getChargeSiteLatLng } from '@/common/utils/utils';
import { Badge } from '@/shadcn/ui/badge';
import { useConnectorData } from '@/tanstack/queries/connector/use-connector.query';

import { ConnectorPageSkeleton } from './connector.page-skeleton';
import { ConnectorSettingsMenu } from './connector-settings-menu';

const columns = [
  ...baseTariffTableColumns,
  {
    accessorKey: 'status',
    header: () => <span className="text-xs ml-auto w-28">{t('CONNECTOR_PAGE.STATUS')}</span>,
  },
];

export const ConnectorPage = () => {
  const { t } = useTranslation();
  const { connectorId } = useParams();
  const { data: connectorData, isLoading } = useConnectorData(Number(connectorId));

  if (isLoading || !connectorData) {
    return <ConnectorPageSkeleton />;
  }

  const chargeSiteLatLng = getChargeSiteLatLng(connectorData.chargeSite);

  return (
    <PageLayout
      title={t('CONNECTOR_PAGE.PAGE_NAME', { readableId: connectorData.readableId })}
      backUrl={`/chargers/${connectorData.chargePoint.id}`}
      rightSlot={
        connectorData.isArchived ? (
          <Badge variant="ARCHIVED" />
        ) : (
          <ConnectorSettingsMenu connector={connectorData} />
        )
      }
    >
      <div className="pb-4">
        <div className="flex mb-4">
          <div className="w-2/5 flex-col gap-4">
            <Widget
              fullWidth
              title={t('CONNECTOR_PAGE.CHARGE_POINT_READABLE_ID')}
              content={connectorData.chargePoint.uniqId}
              contentProps={{ size: '2xl' }}
              reactIcon={<BarcodeIcon />}
            />

            <div className="flex mt-4 gap-4">
              <Widget
                title={t('CONNECTOR_PAGE.ADDRESS')}
                content={connectorData.chargeSite.address}
                fullWidth
                reactIcon={<HomeIcon />}
              />
              <Widget
                title={t('COORDINATES')}
                content={
                  <div>
                    {chargeSiteLatLng.lat}
                    <br />
                    {chargeSiteLatLng.lng}
                  </div>
                }
                fullWidth
                reactIcon={<GeolocationIcon />}
              />
            </div>
            <div className="flex mt-4 gap-4">
              <Widget
                title={t('CONNECTOR_PAGE.CONNECTOR_TYPE')}
                content={connectorData.typeDescription}
                fullWidth
                reactIcon={
                  <ConnectorIcon width={48} height={48} connectorType={connectorData.type} />
                }
              />
              <Widget
                title={t('CONNECTOR_PAGE.CONNECTOR_MAX_POWER')}
                content={fromWattToKiloWatt(connectorData.powerWt)}
                contentProps={{ size: '5xl' }}
                fullWidth
                reactIcon={<LightningIcon />}
              />
            </div>
          </div>
          <div className="w-3/5 ml-4 z-0">
            <div className="h-full mb-4 ">
              <MapComponent center={chargeSiteLatLng}>
                <LocationMarker
                  position={chargeSiteLatLng}
                  isSelected
                  tooltipContent={connectorData.chargeSite.title}
                />
              </MapComponent>
            </div>
          </div>
        </div>
        <TariffTable data={[connectorData.tariff]} columns={columns} />
      </div>
    </PageLayout>
  );
};
