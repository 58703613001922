import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { deleteChargePoint } from '@/_api';

type Variables = {
  chargeSiteId: number;
  chargePointId: number;
  connectorIds: number[];
};

export const useDeleteChargePointMutation = () => {
  return useMutation({
    mutationFn: ({ chargePointId }: Variables) =>
      deleteChargePoint({ path: { id: chargePointId } }).then(({ data }) => data),

    onSettled: (_, __, { chargeSiteId, chargePointId, connectorIds }) => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_SITE_LIST],
        [QUERY_KEY.CHARGE_SITE_STATISTIC],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
        ...connectorIds.map((connectorId) => [QUERY_KEY.CONNECTOR_ITEM, connectorId]),
      ]);

      return bulkRefetchQueries([
        [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        [QUERY_KEY.CHARGE_POINT_ITEM, chargePointId],
      ]);
    },
  });
};
