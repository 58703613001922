import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { cpmsCreateTariff, type CpmsCreateTariffRequestDto } from '@/_api';

export const useCreateTariffMutation = () =>
  useMutation({
    mutationFn: (body: CpmsCreateTariffRequestDto) =>
      cpmsCreateTariff({ body }).then(({ data }) => data),

    onSettled: () => {
      bulkInvalidateQueries([[QUERY_KEY.TARIFF_LIST], [QUERY_KEY.TARIFF_LIST]]);

      return bulkRefetchQueries([
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
      ]);
    },
  });
