import { PropsWithChildren, ReactElement } from 'react';

import { LatLng, LeafletEventHandlerFnMap } from 'leaflet';
import { MapContainer, TileLayer, useMapEvent } from 'react-leaflet';

import { MAP_SOURCE_URL, MAP_START_POSITION } from '@/common/constants/const';
import { Card } from '@/shadcn/ui/card';

type MapComponentProps = PropsWithChildren<{
  onMapClick?: LeafletEventHandlerFnMap['click'];
  center?: Pick<LatLng, 'lat' | 'lng'>;
  closePopupOnClick?: boolean;
}>;

const MapInnerComponent = ({ children, onMapClick }: MapComponentProps) => {
  useMapEvent('click', onMapClick);

  return children as ReactElement;
};

export const MapComponent = ({
  children,
  onMapClick,
  center,
  closePopupOnClick = true,
}: MapComponentProps) => {
  return (
    <Card className="h-full w-full overflow-hidden">
      <MapContainer
        doubleClickZoom={false}
        zoom={12}
        center={center ?? MAP_START_POSITION}
        closePopupOnClick={closePopupOnClick}
        className="w-full h-full"
      >
        <TileLayer
          url={MAP_SOURCE_URL || ''}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          tileSize={512}
          zoomOffset={-1}
          detectRetina={true}
        />
        {onMapClick ? (
          <MapInnerComponent onMapClick={onMapClick}>{children}</MapInnerComponent>
        ) : (
          children
        )}
      </MapContainer>
    </Card>
  );
};
