import { Trans, useTranslation } from 'react-i18next';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shadcn/ui/select';
import { useTariffsData } from '@/tanstack/queries/tariff/use-tariffs.query';

type Props = {
  chargeSiteTitle: string;
  selectedTariffId: number | null;
  setSelectedTariffId: (tariffId: number) => void;
};

export const ApplyTariffModalContent = ({
  chargeSiteTitle,
  selectedTariffId,
  setSelectedTariffId,
}: Props) => {
  const { t } = useTranslation();
  const { data: tariffs, isLoading: isLoadingTariffs } = useTariffsData();

  return (
    <>
      <Select
        disabled={isLoadingTariffs}
        defaultValue={selectedTariffId?.toString()}
        onValueChange={(value) => setSelectedTariffId(Number(value))}
      >
        <SelectTrigger className="w-full mb-12">
          <SelectValue placeholder={t('CHARGE_SITE_PAGE.SELECT_TARIFF_PLACEHOLDER')} />
        </SelectTrigger>
        <SelectContent>
          {tariffs?.map((tariff) => (
            <SelectItem key={tariff.id} value={tariff.id.toString()}>
              {tariff.title}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Trans components={{ span: <span /> }}>
        {t('CHARGE_SITE_PAGE.APPLY_TARIFF_MODAL_DESCRIPTION', { chargeSiteTitle })}
      </Trans>
    </>
  );
};
