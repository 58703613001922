import { forwardRef, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { cc, cn } from '@/common/utils/utils';

const Table = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      className={cn(
        'relative overflow-hidden border-1.5 border-primary-foreground rounded-md',
        className,
      )}
      ref={ref}
      {...props}
    />
  ),
);

Table.displayName = 'Table';

const TableHeader = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ title: ReactNode; className?: string }>
>(({ className, title, ...props }, ref) => (
  <div>
    <div
      ref={ref}
      className={cn(
        'flex items-center h-14 font-semibold px-6 bg-background rounded-t-md',
        className,
      )}
      {...props}
    >
      {title}
    </div>
    {props.children && (
      <TableRow
        isWithoutDivider
        isWithoutHover
        className="bg-background-muted font-figtree font-normal text-muted h-10 border-b-1.5 border-background-muted"
      >
        {props.children}
      </TableRow>
    )}
  </div>
));

TableHeader.displayName = 'TableHearder';

type TableRowProps = HTMLAttributes<HTMLDivElement> & {
  isWithoutDivider?: boolean;
  isWithoutHover?: boolean;
};

const TableRow = forwardRef<HTMLDivElement, TableRowProps>(
  ({ className, isWithoutDivider, isWithoutHover, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex items-center h-14 bg-background relative transition-colors',
        cc(
          !isWithoutDivider,
          'after:content after:z-10 after:absolute after:bg-primary-foreground after:mx-5 after:top-14 after:left-0 after:right-0 after:h-[1px]',
        ),
        cc(!isWithoutHover, 'hover:bg-primary-light'),
        className,
      )}
      {...props}
    />
  ),
);

TableRow.displayName = 'TableRow';

export { Table, TableRow, TableHeader };
