import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@/shadcn/ui/button';

type Props = {
  isLoading: boolean;
  cancelLinkUrl: string;
};

export const FormBottomBar = ({ isLoading, cancelLinkUrl }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="fixed bottom-0 bg-background-muted left-52 right-4 flex justify-between p-6">
      <Link to={cancelLinkUrl} className="mr-4">
        <Button type="button" variant="outline">
          {t('CANCEL')}
        </Button>
      </Link>
      <Button type="submit" className="min-w-48" isLoading={isLoading} disabled={isLoading}>
        {t('SAVE')}
      </Button>
    </div>
  );
};
