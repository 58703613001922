import { zodResolver } from '@hookform/resolvers/zod';
import { fromKiloWattToWatt, fromWattToKiloWatt, isDefined } from '@ion-hq/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ChargePointEditForm } from '@/common/components/charge-point-edit-form';
import { FormPageSkeleton } from '@/common/components/form.page-skeleton';
import { FormBottomBar } from '@/common/components/form-bottom-bar';
import { PageLayout } from '@/common/layouts/page-layout';
import { useEditChargePointMutation } from '@/tanstack/mutations/charge-point/edit-charge-point-mutation';
import { useChargePointData } from '@/tanstack/queries/charge-point/use-charge-point.query';
import { useTariffsData } from '@/tanstack/queries/tariff/use-tariffs.query';

import { editChargePointFormSchema, EditChargePointFormType } from './edit-charge-point-form.utils';

export const EditChargePointPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chargePointId } = useParams();
  const { isLoading: isLoadingTariffs } = useTariffsData();
  const { data: chargePoint, isLoading: isLoadingChargePoint } = useChargePointData(
    Number(chargePointId),
  );
  const { mutateAsync, isPending } = useEditChargePointMutation();

  const formHandlers = useForm<EditChargePointFormType>({
    values: {
      id: chargePoint?.id ?? 0,
      connectors: (chargePoint?.connectors ?? []).map((connector) => ({
        id: connector.id,
        powerKiloWatt: fromWattToKiloWatt(connector.powerWt),
        type: connector.type,
        tariffId: connector.tariff.id,
      })),
    },
    resolver: zodResolver(editChargePointFormSchema),
  });

  if (isLoadingChargePoint || isLoadingTariffs || !isDefined(chargePoint)) {
    return <FormPageSkeleton />;
  }

  const handleSubmit = formHandlers.handleSubmit(async (data) => {
    await mutateAsync({
      data: data.connectors.map((connector) => ({
        ...connector,
        powerWt: fromKiloWattToWatt(connector.powerKiloWatt),
      })),
      chargePointId: chargePoint.id,
      chargeSiteId: chargePoint.chargeSite.id,
    });

    navigate(`/chargers/${chargePointId}`);
  });

  const archivedConnectorsIds = chargePoint.connectors
    .filter((connector) => connector.isArchived)
    .map((connector) => connector.id);

  return (
    <PageLayout
      title={t('EDIT_CHARGE_POINT_PAGE.PAGE_NAME', { readableId: chargePoint.readableId })}
      backUrl={`/chargers/${chargePointId}`}
    >
      <FormProvider {...formHandlers}>
        <form onSubmit={handleSubmit} className="p-2">
          <ChargePointEditForm
            chargePointReadableId={Number(chargePoint.readableId)}
            archivedConnectorsIds={archivedConnectorsIds}
          />

          <FormBottomBar cancelLinkUrl={`/chargers/${chargePointId}`} isLoading={isPending} />
        </form>
      </FormProvider>
    </PageLayout>
  );
};
