import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsChargeTransactionList, GetCpmsChargeTransactionListData } from '@/_api';

export const useChargeTransactionsList = (query: GetCpmsChargeTransactionListData['query']) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_TRANSACTION_LIST, query?.page],

    queryFn: () => getCpmsChargeTransactionList({ query }).then(({ data }) => data),
  });
};
