import { PropsWithChildren } from 'react';

import GearIcon from '@/assets/icons/gear.svg?react';
import { Icon } from '@/common/components/icon';
import { Button } from '@/shadcn/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/shadcn/ui/dropdown-menu.tsx';

type Props = {
  title: string;
};

export const SettingsMenu = ({ title = 'Settings', children }: PropsWithChildren<Props>) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="text">
        {title}
        <Icon reactIcon={<GearIcon />} />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">{children}</DropdownMenuContent>
  </DropdownMenu>
);
