import { useTranslation } from 'react-i18next';

import { DisabledSettingsButton } from '@/common/components/disabled-settings-button';
import { PageLayout } from '@/common/layouts/page-layout';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const TariffPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t('SKELETON.LOADING')}
      rightSlot={<DisabledSettingsButton title={t('TARIFF_PAGE.SETTINGS')} />}
      backUrl="/tariffs"
    >
      <div className="flex gap-4 mb-4">
        <div className="w-2/5 flex flex-col gap-4">
          <Skeleton height="widget" />
          <Skeleton height="widget" />
          <Skeleton height="widget" />
        </div>
        <div className="w-3/5">
          <Skeleton />
        </div>
      </div>
      <Skeleton height="table" />
    </PageLayout>
  );
};
