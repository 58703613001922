import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import LoginIonLogo from '@/assets/logos/login-ion-logo.svg';
import { Card } from '@/shadcn/ui/card';

export const LoginLayout = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex flex-col items-center pt-16">
      <img src={LoginIonLogo} alt="Logo" />
      <Card className="w-card mt-6 py-10 px-6 text-center rounded-lg">
        <Outlet />
      </Card>
      <div className="absolute bottom-16 text-xs text-link">
        <a
          href="https://ion.ae/privacy-policy.html"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          {t('LOGIN_LAYOUT.PRIVACY_POLICY')}
        </a>{' '}
        \{' '}
        <a
          href="https://ion.ae/terms-of-service.html"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          {t('LOGIN_LAYOUT.TERMS_OF_SERVICE')}
        </a>
      </div>
    </div>
  );
};
