import { use as i18nextUse } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zodTranslation from 'zod-i18n-map/locales/en/zod.json';

import translationEN from './translations/en.json';

export const i18nResources = {
  en: {
    translation: translationEN,
    zod: zodTranslation,
  },
} as const;

export const t = await i18nextUse(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    lng: 'en',
    compatibilityJSON: 'v4',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

z.setErrorMap(zodI18nMap);
