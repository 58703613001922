import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsTariffStatistics } from '@/_api';

export const useTariffStatistics = () => {
  return useQuery({
    queryKey: [QUERY_KEY.TARIFF_STATISTIC],
    queryFn: () => getCpmsTariffStatistics().then(({ data }) => data),
  });
};
