import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsTariffsWithDetails } from '@/_api';

export const useTariffsWithDetailsData = () => {
  return useQuery({
    queryKey: [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
    queryFn: () => getCpmsTariffsWithDetails().then(({ data }) => data),
  });
};
