import { ReactNode } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { isDefined } from '@ion-hq/utils';
import { useFormContext } from 'react-hook-form';

import { CONNECTOR_LABEL_MAP } from '@/common/constants/const';
import { ArrayEditChargePointFormType } from '@/pages/add-chargers/add-chargers';
import { EditChargePointFormType } from '@/pages/charge-point/edit-charge-point-page/edit-charge-point-form.utils';
import { Input } from '@/shadcn/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shadcn/ui/select';
import { useTariffsData } from '@/tanstack/queries/tariff/use-tariffs.query';

import { ErrorText } from './error-text';

import { ConnectorTypeEnum } from '@/_api';

type Props = {
  connectorIndex: number;
  chargePointIndex?: number;
  connectorReadableId: string | undefined;
  archivedConnectorsIds: number[];
  actionsSlot?: ReactNode;
};

export const FormConnectorFields = ({
  connectorIndex,
  chargePointIndex,
  connectorReadableId,
  archivedConnectorsIds,
  actionsSlot,
}: Props) => {
  const { data: tariffs } = useTariffsData();
  const { watch, setValue, register, formState } = useFormContext<
    EditChargePointFormType | ArrayEditChargePointFormType
  >();

  const baseName = isDefined(chargePointIndex)
    ? (`chargePoints.${chargePointIndex}.` as const)
    : '';
  const connectorId = watch(`${baseName}connectors.${connectorIndex}.id`);
  const typeName = `${baseName}connectors.${connectorIndex}.type` as const;
  const tariffIdName = `${baseName}connectors.${connectorIndex}.tariffId` as const;
  const powerKiloWattName = `${baseName}connectors.${connectorIndex}.powerKiloWatt` as const;

  const isArchived = isDefined(connectorId) && Boolean(archivedConnectorsIds.includes(connectorId));

  return (
    <div className="mb-6">
      <div className="flex justify-between gap-4 items-between px-8">
        <Input className="w-28" disabled value={connectorReadableId ?? ''} />
        <Select
          disabled={isArchived}
          defaultValue={watch(typeName).toString()}
          onValueChange={(value) => setValue(typeName, Number(value) as ConnectorTypeEnum)}
        >
          <SelectTrigger className="min-w-36 grow-0">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(CONNECTOR_LABEL_MAP).map(([key, value]) => (
              <SelectItem key={key} value={key}>
                {value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="relative w-36 grow-0">
          <Input
            disabled={isArchived}
            className="relative"
            type="number"
            {...register(powerKiloWattName, { valueAsNumber: true })}
          />
          <span className="absolute top-4 right-3 pr-1 text-muted bg-background">kW</span>
        </div>
        <Select
          disabled={isArchived}
          defaultValue={watch(tariffIdName).toString()}
          onValueChange={(value) => setValue(tariffIdName, Number(value))}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {tariffs?.map((tariff) => (
              <SelectItem key={tariff.id} value={tariff.id.toString()}>
                {tariff.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {actionsSlot}
      </div>
      <ErrorMessage
        errors={formState.errors}
        name={powerKiloWattName}
        render={({ message }) => (
          <div className="pl-6 ml-72 -mt-4 -mb-2">
            <ErrorText>{message}</ErrorText>
          </div>
        )}
      />
    </div>
  );
};
