import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FormPageSkeleton } from '@/common/components/form.page-skeleton';
import { PageLayout } from '@/common/layouts/page-layout';
import { useUpdateChargeSiteMutation } from '@/tanstack/mutations/charge-site/update-charge-site.mutation';
import { useChargeSiteData } from '@/tanstack/queries/charge-site/use-charge-site-data.query';

import { ChargeSiteForm } from '../add-charge-site-page/charge-site-form';

import { UpdateChargeSiteData } from '@/_api';

export const EditChargeSitePage = () => {
  const { t } = useTranslation();
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));
  const navigate = useNavigate();
  const { mutateAsync: updateChargeSite, isPending } = useUpdateChargeSiteMutation();

  const handleSubmit = async (body: UpdateChargeSiteData['body']) => {
    if (!chargeSiteId) {
      return;
    }
    await updateChargeSite({ chargeSiteId: Number(chargeSiteId), body });
    navigate(`/locations/${chargeSiteId}`);
  };

  const defaultValues = useMemo(
    () =>
      chargeSite && {
        ...chargeSite,
        address: chargeSite.address ?? undefined,
        description: chargeSite.description ?? undefined,
      },
    [chargeSite],
  );

  if (!chargeSite) {
    return <FormPageSkeleton />;
  }

  return (
    <PageLayout title={t('EDIT_CHARGE_SITE_PAGE.PAGE_NAME')} backUrl={`/locations/${chargeSiteId}`}>
      <ChargeSiteForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isLoading={isPending || isLoading}
        chargeSiteId={chargeSite.id}
      />
    </PageLayout>
  );
};
