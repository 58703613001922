import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { addChargePointsAndConnectors, CpmsAddChargePointsAndConnectorsRequestDto } from '@/_api';

type Variables = {
  data: CpmsAddChargePointsAndConnectorsRequestDto;
  chargeSiteId: number;
};

export const useAddChargePointsAndConnectorsMutation = () =>
  useMutation({
    mutationFn: ({ data, chargeSiteId }: Variables) =>
      addChargePointsAndConnectors({ body: data, path: { id: chargeSiteId } }).then(
        ({ data }) => data,
      ),

    onSettled: (_, __, { chargeSiteId }) => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_SITE_LIST],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [QUERY_KEY.CHARGE_SITE_STATISTIC],
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
      ]);

      return bulkRefetchQueries([[QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId]]);
    },
  });
