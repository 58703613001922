import { useMutation } from '@tanstack/react-query';

import { chargePointClearCache } from '@/_api';

export const useChargePointClearCacheMutation = () => {
  return useMutation({
    mutationFn: (chargePointId: number) =>
      chargePointClearCache({ path: { id: chargePointId } }).then(({ data }) => data),
  });
};
