import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsChargeSiteConnectors } from '@/_api';

export const cpmsChargeSiteConnectorsStatsQueryFn = ({
  queryKey,
}: QueryFunctionContext<[string, number]>) =>
  getCpmsChargeSiteConnectors({ path: { id: queryKey[1] } }).then(({ data }) => data);

export const useChargeSiteConnectorsStats = (siteId: number) =>
  useQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_CONNECTOR_STATS, siteId],
    queryFn: cpmsChargeSiteConnectorsStatsQueryFn,
    gcTime: 0,
  });
