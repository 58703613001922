import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsChargeTransaction } from '@/_api';

export const useChargeTransactionData = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_TRANSACTION, id],
    queryFn: () => getCpmsChargeTransaction({ path: { id } }).then(({ data }) => data),
  });
};
