import { useTranslation } from 'react-i18next';

import { Skeleton } from '@/shadcn/ui/skeleton';

import { PageLayout } from '../layouts/page-layout';

export const FormPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <PageLayout title={t('SKELETON.LOADING')}>
      <Skeleton height="table" />
    </PageLayout>
  );
};
