import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsChargeSite } from '@/_api';

export const useChargeSiteData = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, id],
    queryFn: () => getCpmsChargeSite({ path: { id } }).then(({ data }) => data),
  });
};
