import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AddChargersSvg from '@/assets/images/add-chargers.svg';
import { ChargePointsTable } from '@/common/components/charge-points-table';
import { Button } from '@/shadcn/ui/button.tsx';

import { CpmsChargeSiteItemResponseDto } from '@/_api';

type Props = {
  chargeSiteId: number;
  data: CpmsChargeSiteItemResponseDto['chargePoints'][number][];
};

export const ChargeSiteChargePointList = ({ data, chargeSiteId }: Props) => {
  const { t } = useTranslation();

  if (data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full my-16 gap-6">
        <img src={AddChargersSvg} alt="charger-icon" />

        <Link to={`/locations/${chargeSiteId}/add-chargers`}>
          <Button variant="secondary">{t('CHARGE_SITE_PAGE.ADD_CHARGE_POINTS')}</Button>
        </Link>
      </div>
    );
  }

  return <ChargePointsTable data={data} isStandalone={true} chargeSiteId={chargeSiteId} />;
};
