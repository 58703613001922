import { ChargeSitesTable } from '@/common/components/charge-sites-table';

import { ChargeSitesEmpty } from './charge-sites-empty';

import { CpmsChargeSiteListResponseDto } from '@/_api';

type Props = {
  data: CpmsChargeSiteListResponseDto;
};

export const ChargeSiteListPageContent = ({ data }: Props) => {
  if (data.totalItems === 0) {
    return <ChargeSitesEmpty />;
  }
  const { items, ...pagination } = data;

  return <ChargeSitesTable data={items} pagination={pagination} />;
};
