import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { createChargeSite, type CreateChargeSiteData } from '@/_api';

export const useCreateChargeSiteMutation = () =>
  useMutation({
    mutationFn: (body: CreateChargeSiteData['body']) =>
      createChargeSite({ body }).then(({ data }) => data),

    onSettled: () => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_SITE_STATISTIC],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
      ]);

      return bulkRefetchQueries([[QUERY_KEY.CHARGE_SITE_LIST]]);
    },
  });
