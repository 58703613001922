import { QueryCache, QueryClient } from '@tanstack/react-query';

import { i18nResources, t } from '@/common/i18n/i18n';
import {
  HttpException,
  isForbiddenError,
  isInternalServerError,
  isNotFoundError,
  isUnauthorizedError,
} from '@/common/utils/http.utils';
import { showErrorToast } from '@/shadcn/hooks/use-toast';

type IonError = HttpException & { body: { shortCode: string; message: string } };

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: IonError;
  }
}

const shouldThrowOnError = (error: unknown) => {
  return (
    isNotFoundError(error) ||
    isUnauthorizedError(error) ||
    isForbiddenError(error) ||
    isInternalServerError(error)
  );
};

const getErrorMessage = (shortCode: string | undefined) => {
  return t([
    `ERRORS.BACKEND_SHORTCODES.${shortCode as keyof typeof i18nResources.en.translation.ERRORS.BACKEND_SHORTCODES}`,
    'ERRORS.BACKEND_SHORTCODES.DEFAULT',
  ]);
};

const onError = (error: IonError) => {
  console.error({ ...error });
  const errorMessage = getErrorMessage(error.body.shortCode);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (errorMessage) {
    showErrorToast(errorMessage);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      throwOnError: shouldThrowOnError,
      retry: 1,
    },
    mutations: {
      throwOnError: shouldThrowOnError,
      onError,
    },
  },
  queryCache: new QueryCache({ onError }),
});
