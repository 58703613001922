import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsChargeTransactionStatistics } from '@/_api';

export const useChargeTransactionStatistics = () => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_TRANSACTION_STATISTIC],
    queryFn: () => getCpmsChargeTransactionStatistics().then(({ data }) => data),
  });
};
