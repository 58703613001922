import { fromWattToKiloWatt } from '@ion-hq/utils';
import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';

import { ConnectorIcon } from '@/common/components/connector-icon';

import { CpmsTariffWithConnectorsResponseDto } from '@/_api';

export const connectorListColumns: ColumnDef<
  CpmsTariffWithConnectorsResponseDto['connectors'][number]
>[] = [
  {
    accessorKey: 'readableId',
    header: () => (
      <span className="text-xs pl-4 w-20">
        {t('TARIFF_PAGE.CONNECTOR_TABLE.HEADERS.READABLE_ID')}
      </span>
    ),
    cell: (info) => (
      <span className="text-lg font-semibold pl-8 w-20">{info.row.original.readableId}</span>
    ),
  },
  {
    accessorKey: 'uniqId',
    header: () => (
      <span className="text-xs pl-3 w-36">{t('TARIFF_PAGE.CONNECTOR_TABLE.HEADERS.UNIQ_ID')}</span>
    ),
    cell: (info) => <span className="pl-4 w-36">{info.row.original.uniqId}</span>,
  },
  {
    accessorKey: 'power',
    header: () => (
      <span className="text-xs w-24">{t('TARIFF_PAGE.CONNECTOR_TABLE.HEADERS.POWER')}</span>
    ),
    cell: (info) => (
      <span className="font-medium text-lg w-24">
        {fromWattToKiloWatt(info.row.original.powerWt)} kW
      </span>
    ),
  },
  {
    accessorKey: 'type',
    header: () => (
      <span className="text-xs p-2 w-28">{t('TARIFF_PAGE.CONNECTOR_TABLE.HEADERS.TYPE')}</span>
    ),
    cell: (info) => (
      <div className="flex gap-2 p-2 text-muted w-28">
        <ConnectorIcon connectorType={info.row.original.type} />
      </div>
    ),
  },
  {
    accessorKey: 'chargeSite',
    header: () => (
      <span className="text-xs p-2">{t('TARIFF_PAGE.CONNECTOR_TABLE.HEADERS.LOCATION')}</span>
    ),
    cell: (info) => (
      <div className="p-2 w-96 text-left">
        <p className="font-medium text-lg leading-7">
          {info.row.original.chargePoint.chargeSite.title}
        </p>
        <p className="leading-4 text-muted text-xs">
          {info.row.original.chargePoint.chargeSite.address}
        </p>
      </div>
    ),
  },
];
