import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { restoreConnector } from '@/_api';

type Variables = {
  chargeSiteId: number;
  chargePointId: number;
  connectorId: number;
};

export const useRestoreConnectorMutation = () => {
  return useMutation({
    mutationFn: ({ connectorId }: Variables) =>
      restoreConnector({ path: { id: connectorId } }).then(({ data }) => data),

    onSettled: (_, __, { chargeSiteId, chargePointId, connectorId }) => {
      bulkInvalidateQueries([
        [QUERY_KEY.CHARGE_SITE_LIST],
        [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        [QUERY_KEY.CONNECTOR_ITEM, connectorId],
        [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
        [QUERY_KEY.TARIFF_STATISTIC],
        [QUERY_KEY.TARIFF_LIST_WITH_DETAILS],
      ]);

      return bulkRefetchQueries([[QUERY_KEY.CHARGE_POINT_ITEM, chargePointId]]);
    },
  });
};
