import SpinnerIcon from '@/assets/icons/spinner.svg?react';
import { cn } from '@/common/utils/utils';

type Props = { className?: string };

export const Spinner = ({ className }: Props) => (
  <div className={cn('flex items-center justify-center', className)}>
    <SpinnerIcon className="fill-current" />
  </div>
);
