import { QueryFilters, QueryKey } from '@tanstack/react-query';

import { queryClient } from './query-client';

export const bulkRefetchQueries = (queryKeys: QueryKey[]) =>
  Promise.all(queryKeys.map((queryKey) => queryClient.refetchQueries({ queryKey })));

export const bulkInvalidateQueries = (
  queryKeys: QueryKey[],
  type: QueryFilters['type'] = 'all',
) => {
  for (const queryKey of queryKeys) {
    queryClient.invalidateQueries({ queryKey, type });
  }
};
