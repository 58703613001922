import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BASE_BE_URL } from '@/common/constants/const';
import { useToast } from '@/shadcn/hooks/use-toast';
import { CardContent, CardDescription, CardHeader, CardTitle } from '@/shadcn/ui/card';

import { MagicLinkLoginForm } from './magic-link-login-form';

export const EmailLinkLoginPage = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirectTo') ?? '/';

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await sendSignInLinkToEmail(getAuth(), email, {
        url: `${BASE_BE_URL}/auth/verify-email-link?${new URLSearchParams({ email, redirectTo })}`,
        handleCodeInApp: true,
      });
      window.localStorage.setItem('emailForSignIn', email);
      navigate(`/login/sent?${new URLSearchParams({ email })}`);
    } catch {
      showErrorToast(t('EMAIL_LOGIN_PAGE.FAILED_LOGIN_TOAST'));
    }
  };

  return (
    <>
      <CardHeader>
        <CardTitle className="text-xl mb-5">{t('EMAIL_LOGIN_PAGE.HEADER')}</CardTitle>
        <CardDescription className="text-sm text-muted">
          {t('EMAIL_LOGIN_PAGE.SUBHEADER')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <MagicLinkLoginForm onSubmit={onSubmit} />
      </CardContent>
    </>
  );
};
