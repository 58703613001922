import { AllowedCurrency } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FormPageSkeleton } from '@/common/components/form.page-skeleton';
import { PageLayout } from '@/common/layouts/page-layout';
import { useEditTariffMutation } from '@/tanstack/mutations/tariff/edit-tariff.mutation';
import { useTariff } from '@/tanstack/queries/tariff/use-tariff.query';

import { TariffForm, TariffFormSchemaType } from '../add-tariff/tariff-form';
import { DEFAULT_VAT_PERCENTAGE } from '../tariff.const';

export const EditTariffPage = () => {
  const { tariffId } = useParams();
  const { t } = useTranslation();
  const { data: tariff, isLoading } = useTariff(Number(tariffId));
  const { mutateAsync, isPending } = useEditTariffMutation(Number(tariffId));

  const navigate = useNavigate();

  const handleSubmit = async (formData: TariffFormSchemaType) => {
    await mutateAsync({
      currency: formData.currency,
      description: formData.description ?? null,
      ratePerWh: Number(((formData.ratePerKiloWattHour / 1000) * 100).toFixed(2)),
      title: formData.title,
      vatPercent: formData.vatPercent,
    });
    navigate('/tariffs');
  };

  if (!tariff) {
    return <FormPageSkeleton />;
  }

  const defaultValues = {
    title: tariff.title,
    description: tariff.description ?? '',
    currency: tariff.currency as AllowedCurrency,
    vatPercent: DEFAULT_VAT_PERCENTAGE,
    ratePerKiloWattHour: (tariff.ratePerWh * 1000) / 100,
  };

  return (
    <PageLayout title={t('EDIT_TARIFF_PAGE.TITLE', { tariffTitle: tariff.title })}>
      <TariffForm
        defaultValues={defaultValues}
        isLoading={isPending || isLoading}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  );
};
