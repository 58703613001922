import { useTranslation } from 'react-i18next';

import ChargerIcon from '@/assets/icons/charger.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import { Widget } from '@/common/components/widget';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { useChargeSitesStatistics } from '@/tanstack/queries/charge-site/use-charge-sites-statistics.query';

export const ChargeSitesStatistics = () => {
  const { t } = useTranslation();
  const { data: statistics, isLoading } = useChargeSitesStatistics();

  const loader = (
    <>
      <Skeleton height={'widget'} width={'widget'} />
      <Skeleton height={'widget'} width={'widget'} />
    </>
  );

  const content = (
    <>
      <Widget
        title={t('CHARGE_SITES_PAGE.STATISTICS.CHARGE_SITES')}
        content={statistics?.chargeSitesCount}
        reactIcon={<HomeIcon />}
        contentProps={{ size: '5xl' }}
      />
      <Widget
        title={t('CHARGE_SITES_PAGE.STATISTICS.CHARGE_POINTS')}
        content={statistics?.chargePointsCount}
        reactIcon={<ChargerIcon />}
        contentProps={{ size: '5xl' }}
      />
    </>
  );

  return <div className="flex justify-start gap-4 pb-4">{isLoading ? loader : content}</div>;
};
