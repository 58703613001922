import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { updateChargeSite, UpdateChargeSiteData } from '@/_api';

type Payload = {
  chargeSiteId: number;
  body: UpdateChargeSiteData['body'];
};

export const useUpdateChargeSiteMutation = () =>
  useMutation({
    mutationFn: ({ chargeSiteId, body }: Payload) =>
      updateChargeSite({ body, path: { id: chargeSiteId } }).then(({ data }) => data),

    onSettled: async (_, __, { chargeSiteId }) => {
      bulkInvalidateQueries([[QUERY_KEY.CHARGE_SITE_LIST_BY_AREA]]);

      return bulkRefetchQueries([
        [QUERY_KEY.CHARGE_SITE_LIST],
        [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
      ]);
    },
  });
