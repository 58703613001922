import { useQuery } from '@tanstack/react-query';

import { MAP_SELECT_DISTANCE_KM } from '@/common/constants/const';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsFindChargeSiteByArea } from '@/_api';

export const useChargeSitesWithinArea = (latitude: number, longitude: number) => {
  const refetchInterval = 60_000;

  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
    queryFn: () =>
      getCpmsFindChargeSiteByArea({
        query: {
          distance: MAP_SELECT_DISTANCE_KM.toString(),
          latitude: latitude.toString(),
          longitude: longitude.toString(),
        },
      }).then(({ data }) => data),
    refetchInterval,
  });
};
