import { useMutation } from '@tanstack/react-query';

import { bulkInvalidateQueries, bulkRefetchQueries } from '@/tanstack/query.utils';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { type CpmsCreateTariffRequestDto, cpmsUpdateTariff } from '@/_api';

export const useEditTariffMutation = (id: number) =>
  useMutation({
    mutationFn: (body: CpmsCreateTariffRequestDto) =>
      cpmsUpdateTariff({ body, path: { id } }).then(({ data }) => data),

    onSettled: () => {
      bulkInvalidateQueries([
        [QUERY_KEY.TARIFF_LIST],
        [QUERY_KEY.CONNECTOR_ITEM],
        [QUERY_KEY.CHARGE_POINT_ITEM],
      ]);

      return bulkRefetchQueries([[QUERY_KEY.TARIFF_LIST_WITH_DETAILS]]);
    },
  });
