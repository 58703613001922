import ArrowIcon from '@/assets/icons/arrow.svg?react';
import { Icon } from '@/common/components/icon';

import { generatePageElements, PaginationProperties } from './pagination.utils';

type Props = { pagination: PaginationProperties; onSetPage: (page: number) => void };

export const Pagination = ({ pagination, onSetPage }: Props) => {
  const { currentPage, totalPages } = pagination;

  return (
    <div className="ml-auto text-md flex items-center gap-2 px-4">
      <button disabled={currentPage === 1} onClick={() => onSetPage(currentPage - 1)}>
        <Icon
          className={`rotate-180 ${currentPage === 1 ? 'text-muted' : ''}`}
          reactIcon={<ArrowIcon height="20" width="20" />}
        />
      </button>
      {generatePageElements(currentPage, totalPages).map((element) => (
        <button
          key={element}
          disabled={element === '...' || element === currentPage}
          className={`font-figtree font-medium  ${element === currentPage ? 'text-primary' : ''}`}
          onClick={element === '...' ? undefined : () => onSetPage(element)}
        >
          {element}
        </button>
      ))}
      <button disabled={currentPage === totalPages} onClick={() => onSetPage(currentPage + 1)}>
        <Icon
          className={currentPage === totalPages ? 'text-muted' : ''}
          reactIcon={<ArrowIcon height="20" width="20" />}
        />
      </button>
    </div>
  );
};
