import { ReactNode } from 'react';

import { cn } from '@/common/utils/utils';

import { Spinner } from './spinner';

type IconProps = {
  /** Inline SVG icon imported with "?react" passed as JSX */
  reactIcon: ReactNode;
  className?: string;
  isLoading?: boolean;
};
/**Use this for icons created by designer using strokes. It will take the outer font color by default*/
export const Icon = ({ reactIcon, className, isLoading }: IconProps) => {
  return (
    <div className={cn('[&>svg_*]:stroke-current', className)}>
      {isLoading ? <Spinner /> : reactIcon}
    </div>
  );
};
