import { MouseEvent } from 'react';

import { useTranslation } from 'react-i18next';

import ArchiveIcon from '@/assets/icons/archive.svg?react';
import RestartIcon from '@/assets/icons/restart.svg?react';
import TrashIcon from '@/assets/icons/trash-can.svg?react';
import { Icon } from '@/common/components/icon';
import { Modal } from '@/common/components/modal';
import { SettingsMenu } from '@/common/components/settings-menu';
import { useToast } from '@/shadcn/hooks/use-toast';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';
import { useChargePointClearCacheMutation } from '@/tanstack/mutations/charge-point/clear-cache.mutation';
import { useDeleteChargePointMutation } from '@/tanstack/mutations/charge-point/delete-charge-point.mutation';
import { useRestartChargePointMutation } from '@/tanstack/mutations/charge-point/restart-charge-point.mutation';
import { useRestoreChargePointMutation } from '@/tanstack/mutations/charge-point/restore-charge-point.mutation';

import { ChargePointResetBodyRequestDto, CpmsChargePointResponseDto } from '@/_api';

type Props = {
  chargePoint: CpmsChargePointResponseDto;
};

export const ChargePointSettingsMenu = ({ chargePoint }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteChargePoint, isPending: isPendingDelete } =
    useDeleteChargePointMutation();
  const { mutateAsync: restoreChargePoint, isPending: isPendingRestore } =
    useRestoreChargePointMutation();
  const { mutateAsync: restartChargePoint, isPending: isPendingRestart } =
    useRestartChargePointMutation();
  const { mutateAsync: clearCache, isPending: isPendingClearCache } =
    useChargePointClearCacheMutation();

  const { showErrorToast, showSuccessToast } = useToast();

  const handleDelete = async () => {
    await deleteChargePoint({
      chargeSiteId: chargePoint.chargeSite.id,
      chargePointId: chargePoint.id,
      connectorIds: chargePoint.connectors.map((connector) => connector.id),
    });
  };

  const handleRestore = async () => {
    await restoreChargePoint({
      chargeSiteId: chargePoint.chargeSite.id,
      chargePointId: chargePoint.id,
      connectorIds: chargePoint.connectors.map((connector) => connector.id),
      refetch: 'none',
    });
  };

  const handleRestart = async (
    event: MouseEvent<HTMLDivElement>,
    resetType: ChargePointResetBodyRequestDto['resetType'],
  ) => {
    event.preventDefault();
    const response = await restartChargePoint({ chargePointId: chargePoint.id, resetType });

    if (!response) {
      return;
    }

    if (response.isSuccessful) {
      showSuccessToast(t('CHARGE_POINT_PAGE.RESTART_SUCCESS', { resetType }));
    } else {
      showErrorToast(t('CHARGE_POINT_PAGE.RESTART_FAILED', { resetType }));
    }
  };

  const handleClearCache = async () => {
    const result = await clearCache(chargePoint.id);

    if (!result) {
      return;
    }

    if (result.isSuccessful) {
      showSuccessToast(t('CHARGE_POINT_PAGE.CLEAR_CACHE_SUCCESS'));
    } else {
      showErrorToast(t('CHARGE_POINT_PAGE.CLEAR_CACHE_REJECTED'));
    }
  };

  return (
    <SettingsMenu title="Charger Settings">
      <DropdownMenuItem className="cursor-pointer" onClick={handleClearCache}>
        <Icon
          reactIcon={<TrashIcon width={16} height={16} />}
          className="mr-2"
          isLoading={isPendingClearCache}
        />
        {t('CHARGE_POINT_PAGE.CLEAR_CACHE')}
      </DropdownMenuItem>
      {chargePoint.isArchived ? (
        <Modal
          title={t('CHARGE_POINT_PAGE.UNARCHIVE_CHARGE_POINT')}
          subtitle={chargePoint.chargeSite.title}
          variant="default"
          trigger={
            <DropdownMenuItem
              className="cursor-pointer"
              onSelect={(event) => event.preventDefault()}
            >
              <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
              {t('CHARGE_POINT_PAGE.UNARCHIVE_CHARGE_POINT')}
            </DropdownMenuItem>
          }
          description={t('CHARGE_POINT_PAGE.UNARCHIVE_CHARGE_POINT_MODAL_DESCRIPTION', {
            chargePoint,
          })}
          renderConfirmButton={(close) => (
            <Button
              className="min-w-48"
              isLoading={isPendingRestore}
              onClick={async () => {
                await handleRestore();
                close();
              }}
            >
              {t('UNARCHIVE')}
            </Button>
          )}
        />
      ) : (
        <>
          <Modal
            title={t('CHARGE_POINT_PAGE.ARCHIVE_CHARGE_POINT')}
            subtitle={t('CHARGE_POINT_PAGE.ARCHIVE_MODAL_SUBTITLE', { chargePoint })}
            variant="destructive"
            trigger={
              <DropdownMenuItem
                className="cursor-pointer"
                onSelect={(event) => event.preventDefault()}
              >
                <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
                {t('CHARGE_POINT_PAGE.ARCHIVE_CHARGE_POINT')}
              </DropdownMenuItem>
            }
            description={t('CHARGE_POINT_PAGE.ARCHIVE_MODAL_DESCRIPTION', { chargePoint })}
            renderConfirmButton={(close) => (
              <Button
                className="min-w-48"
                isLoading={isPendingDelete}
                onClick={async () => {
                  await handleDelete();
                  close();
                }}
              >
                {t('ARCHIVE')}
              </Button>
            )}
          />
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={(event) => handleRestart(event, 'Soft')}
          >
            <Icon
              reactIcon={<RestartIcon />}
              className="w-4 h-4 mr-2"
              isLoading={isPendingRestart}
            />
            {t('CHARGE_POINT_PAGE.SOFT_RESTART')}
          </DropdownMenuItem>
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={(event) => handleRestart(event, 'Hard')}
          >
            <Icon
              reactIcon={<RestartIcon />}
              className="mr-2 w-4 h-4 text-destructive"
              isLoading={isPendingRestart}
            />
            {t('CHARGE_POINT_PAGE.HARD_RESTART')}
          </DropdownMenuItem>
        </>
      )}
    </SettingsMenu>
  );
};
