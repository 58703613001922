import { zodResolver } from '@hookform/resolvers/zod';
import { isDefined } from '@ion-hq/utils';
import { LeafletEventHandlerFnMap } from 'leaflet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import SearchIcon from '@/assets/icons/search.svg';
import { ErrorText } from '@/common/components/error-text';
import { FormBottomBar } from '@/common/components/form-bottom-bar';
import { LocationMarker } from '@/common/components/leaflet/location-marker';
import { MapComponent } from '@/common/components/leaflet/map';
import { t } from '@/common/i18n/i18n';
import { Button } from '@/shadcn/ui/button';
import { Input } from '@/shadcn/ui/input';
import { TableHeader } from '@/shadcn/ui/table';
import { Textarea } from '@/shadcn/ui/textarea';

import { CreateChargeSiteData, UpdateChargeSiteData } from '@/_api';

const chargeSiteFormSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number({ message: t('CHARGE_SITE_FORM.LOCATION_ERROR_MESSAGE') }),
  longitude: z.number({ message: t('CHARGE_SITE_FORM.LOCATION_ERROR_MESSAGE') }),
});

type ChargeSiteFormSchemaType = z.infer<typeof chargeSiteFormSchema>;

type Props = {
  isLoading: boolean;
  defaultValues?: UpdateChargeSiteData['body'] | CreateChargeSiteData['body'];
  chargeSiteId?: number;
  onSubmit: SubmitHandler<ChargeSiteFormSchemaType>;
};

export const ChargeSiteForm = ({ isLoading, defaultValues, onSubmit, chargeSiteId }: Props) => {
  const { register, handleSubmit, formState, watch, setValue } = useForm<ChargeSiteFormSchemaType>({
    resolver: zodResolver(chargeSiteFormSchema),
    values: defaultValues,
  });

  const handleMapClick: LeafletEventHandlerFnMap['click'] = ({ latlng }) => {
    setValue('latitude', latlng.lat, { shouldValidate: true });
    setValue('longitude', latlng.lng, { shouldValidate: true });
  };

  const position = {
    lat: watch('latitude'),
    lng: watch('longitude'),
  };

  const isLocationMarkerVisible = isDefined(position.lat) && isDefined(position.lng);
  const cancelUrl = `/locations${chargeSiteId ? '/' : ''}${chargeSiteId ?? ''}`;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="border-1.5 border-primary-foreground rounded-md h-[calc(100%-96px)]"
    >
      <TableHeader title={t('CHARGE_SITE_FORM.COORDINATES')} />
      <div className="flex gap-6 grow p-6 h-[calc(100%-56px)]">
        <div className="w-1/2 flex flex-wrap gap-6">
          <div className="flex flex-col flex-1 gap-5">
            <Input
              {...register('title')}
              placeholder={t('CHARGE_SITE_FORM.CHARGE_SITE_TITLE')}
              error={formState.errors.title?.message}
              disabled={isLoading}
            />
            <Textarea
              {...register('description')}
              placeholder={t('CHARGE_SITE_FORM.DESCRIPTION')}
              className="h-[calc(100%-1em)]"
              error={formState.errors.description?.message}
              disabled={isLoading}
            />
            <div className="relative">
              <span className="absolute left-3 top-4 text-muted">
                <img src={SearchIcon} alt="Search icon" />
              </span>
              <Input
                {...register('address')}
                placeholder={t('CHARGE_SITE_FORM.ADDRESS')}
                className="pl-12"
                error={formState.errors.address?.message}
                disabled
              />
              <Button disabled className="ml-auto mr-0 block">
                Search
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full [&>div>div.leaflet-grab]:cursor-default active:[&>div>div.leaflet-grab]:cursor-grabbing">
          <MapComponent
            onMapClick={handleMapClick}
            closePopupOnClick={false}
            center={isLocationMarkerVisible ? position : undefined}
          >
            {isLocationMarkerVisible && (
              <LocationMarker
                position={position}
                eventHandlers={{ click: (event) => event.target.closePopup() }}
              />
            )}
          </MapComponent>
          <ErrorText>
            {formState.errors.latitude?.message ?? formState.errors.longitude?.message}
          </ErrorText>
        </div>
      </div>
      <FormBottomBar cancelLinkUrl={cancelUrl} isLoading={isLoading} />
    </form>
  );
};
