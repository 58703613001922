import { useMutation } from '@tanstack/react-query';

import { unlockConnector } from '@/_api';

export const useUnlockConnectorMutation = () => {
  return useMutation({
    mutationFn: (connectorId: number) =>
      unlockConnector({ path: { id: connectorId } }).then(({ data }) => data),
  });
};
