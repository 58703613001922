import isObject from 'lodash/isObject';

export const isHttpError = (error: unknown) => isObject(error) && 'status' in error;
export const isUnauthorizedError = (error: unknown) => isHttpError(error) && error.status === 401;
export const isForbiddenError = (error: unknown) => isHttpError(error) && error.status === 403;
export const isInternalServerError = (error: unknown) => isHttpError(error) && error.status === 500;
export const isNotFoundError = (error: unknown) => isHttpError(error) && error.status === 404;

export class HttpException extends Error {
  public constructor(
    public readonly status: number,
    public readonly body: Record<string, unknown>,
  ) {
    super('HttpException');
  }
}
