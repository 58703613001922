import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '@/common/layouts/page-layout';
import { useCreateChargeSiteMutation } from '@/tanstack/mutations/charge-site/create-charge-site.mutation';

import { ChargeSiteForm } from './charge-site-form';

import { CreateChargeSiteData } from '@/_api';

export const AddChargeSitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: createChargeSite, isPending } = useCreateChargeSiteMutation();

  const handleSubmit = async (body: CreateChargeSiteData['body']) => {
    await createChargeSite(body);
    navigate('/locations');
  };

  return (
    <PageLayout title={t('ADD_CHARGE_SITE_PAGE.PAGE_NAME')} backUrl="/locations">
      <ChargeSiteForm onSubmit={handleSubmit} isLoading={isPending} />
    </PageLayout>
  );
};
