import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { getCpmsTariffs } from '@/_api';

export const useTariffsData = () => {
  return useQuery({
    queryKey: [QUERY_KEY.TARIFF_LIST],
    queryFn: () => getCpmsTariffs().then(({ data }) => data),
  });
};
