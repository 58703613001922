import { isDefined } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';

import { PageLayout } from '@/common/layouts/page-layout';
import { usePagination } from '@/common/pagination/pagination.hooks';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { useChargeTransactionsList } from '@/tanstack/queries/charge-transaction/use-charge-transactions-list.query';
import { useChargeTransactionStatistics } from '@/tanstack/queries/charge-transaction/use-charge-transactions-statistics.query';

import { ChargeTransactionsPageContent } from './charge-transactions-page-content';
import { ChargeTransactionStatistics } from './charge-transactions-statistics';

export const ChargeTransactionsListPage = () => {
  const { t } = useTranslation();
  const { page } = usePagination();
  const { data } = useChargeTransactionsList({ page });
  const { data: statistics } = useChargeTransactionStatistics();

  return (
    <PageLayout title={t('CHARGE_TRANSACTIONS_PAGE.PAGE_NAME')}>
      {isDefined(statistics) ? (
        <ChargeTransactionStatistics data={statistics} />
      ) : (
        <Skeleton height="widget" width="widget" />
      )}

      {isDefined(data) ? (
        <ChargeTransactionsPageContent data={data} />
      ) : (
        <Skeleton height="table" className="mt-4" />
      )}
    </PageLayout>
  );
};
