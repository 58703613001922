import { fromWattToKiloWatt, isDefined, Money } from '@ion-hq/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import CalendarIcon from '@/assets/icons/calendar.svg?react';
import ClockIcon from '@/assets/icons/clock.svg?react';
import DownloadIcon from '@/assets/icons/downland.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import PlugIcon from '@/assets/icons/plug.svg?react';
import UserIcon from '@/assets/icons/user.svg?react';
import { Icon } from '@/common/components/icon';
import { LocationMarker } from '@/common/components/leaflet/location-marker';
import { MapComponent } from '@/common/components/leaflet/map';
import { Widget } from '@/common/components/widget';
import { PageLayout } from '@/common/layouts/page-layout';
import {
  formatDate,
  formatDuration,
  formatTariffRatePrice,
  formatTime,
  getChargeSiteLatLng,
  saveFile,
} from '@/common/utils/utils';
import { Button } from '@/shadcn/ui/button';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';
import { useDownloadInvoiceMutation } from '@/tanstack/queries/charge-transaction/download-invoice.mutation';
import { useChargeTransactionData } from '@/tanstack/queries/charge-transaction/use-charge-transaction.query';

import { ChargeTransactionPageSkeleton } from './charge-transaction.page-skeleton';

export const ChargeTransactionPage = () => {
  const { t } = useTranslation();
  const { chargeTransactionId = '' } = useParams();
  const { data } = useChargeTransactionData(Number(chargeTransactionId));
  const { mutateAsync, isPending } = useDownloadInvoiceMutation();

  const handleDownloadInvoice = async () => {
    const blob = await mutateAsync(chargeTransactionId);

    if (blob) {
      saveFile(blob, `invoice-${chargeTransactionId}.pdf`);
    }
  };

  if (!data) {
    return <ChargeTransactionPageSkeleton />;
  }

  const payment = isDefined(data.calculatedPayment)
    ? Money.fromPair(data.calculatedPayment, data.tariff.currency)
    : null;

  const vat = isDefined(data.vatAmount)
    ? Money.fromPair(data.vatAmount, data.tariff.currency)
    : null;

  return (
    <PageLayout
      title={t('CHARGE_TRANSACTION_PAGE.PAGE_NAME', { chargeTransactionId })}
      backUrl={'/sessions'}
      rightSlot={
        <Button variant="text" onClick={handleDownloadInvoice} isLoading={isPending}>
          Download PDF <Icon reactIcon={<DownloadIcon />} />
        </Button>
      }
    >
      <div className="flex gap-4 pb-4">
        <div className="w-1/2">
          <div className="flex gap-4 mb-4">
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.DATE')}
              content={data.chargingEndedAt ? formatDate(data.chargingEndedAt) : ''}
              fullWidth
              contentProps={{ size: '2xl' }}
              reactIcon={<Icon reactIcon={<CalendarIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.TIME')}
              content={data.chargingEndedAt ? formatTime(data.chargingEndedAt) : ''}
              fullWidth
              contentProps={{ size: '2xl' }}
              reactIcon={<Icon reactIcon={<ClockIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.USER')}
              content={data.user.phoneNumber ?? 'N/A'}
              fullWidth
              reactIcon={<Icon reactIcon={<UserIcon />} />}
            />
          </div>
          <div className="flex gap-4 mb-4">
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.SESSION_ID')}
              content={data.id}
              fullWidth
              reactIcon={<Icon reactIcon={<BarcodeIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.CHARGE_SITE')}
              content={data.chargePoint.chargeSite.title}
              fullWidth
              reactIcon={<Icon reactIcon={<HomeIcon />} />}
            />
            <Widget
              title={t('CHARGE_TRANSACTION_PAGE.CONNECTOR_UNIQ_ID')}
              content={data.connector.uniqId}
              fullWidth
              reactIcon={<Icon reactIcon={<PlugIcon />} />}
            />
          </div>
          <Table className="mb-4">
            <TableHeader title={t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.TITLE')}>
              <div className="text-xs ml-6 w-4">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.TIME')}
              </div>
              <div className="text-xs ml-16 w-20">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.DURATION')}
              </div>
              <div className="text-xs ml-10 w-20">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.ENERGY')}
              </div>
              <div className="text-xs ml-auto w-32">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.TARIFF_RATE')}
              </div>
              <div className="text-xs ml-10 w-32">
                {t('CHARGE_TRANSACTION_PAGE.CHARGING_INFORMATION_TABLE.HEADERS.SESSION_COST')}
              </div>
            </TableHeader>
            <TableRow>
              <div className="ml-6 w-4 font-figtree">
                {data.chargingEndedAt ? formatTime(data.chargingEndedAt) : ''}
              </div>
              <div className="ml-16 w-20 font-figtree">
                {data.sessionDurationSec ? formatDuration(data.sessionDurationSec) : null}
              </div>
              <div className="ml-10 w-20 text-xl font-medium">
                {data.energyTransferredWh ? fromWattToKiloWatt(data.energyTransferredWh) : null}
              </div>
              <div className="ml-auto w-32 text-xl font-medium">
                {formatTariffRatePrice(data.tariff)}
              </div>
              <div className="ml-10 w-32 text-xl font-medium">
                {payment?.toFormat('0.00')} {payment?.getCurrency()}
              </div>
            </TableRow>
          </Table>
          <Table>
            <TableHeader title={t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.TITLE')}>
              <div className="text-xs ml-6 w-32">
                {t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.HEADERS.METHOD')}
              </div>
              <div className="text-xs ml-auto w-32">
                {t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.HEADERS.VAT', {
                  vatPercent: data.tariff.vatPercent,
                })}
              </div>
              <div className="text-xs ml-10 w-32">
                {t('CHARGE_TRANSACTION_PAGE.PAYMENT_TABLE.HEADERS.TOTAL_AMOUNT')}
              </div>
            </TableHeader>
            <TableRow>
              <div className="ml-6 w-32 font-medium text-xl">{data.paymentMethodDescription}</div>
              <div className="ml-auto w-32 text-xl font-medium">
                {vat?.toFormat('0.00')} {vat?.getCurrency()}
              </div>
              <div className="ml-10 w-32 text-xl font-medium">
                {payment?.toFormat('0.00')} {payment?.getCurrency()}
              </div>
            </TableRow>
          </Table>
        </div>
        <div className="w-1/2">
          <MapComponent>
            <LocationMarker position={getChargeSiteLatLng(data.chargePoint.chargeSite)} />
          </MapComponent>
        </div>
      </div>
    </PageLayout>
  );
};
